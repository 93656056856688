<app-module-configuration
  [moduleForm]="moduleForm"
  [pointOfInterestList]="pointOfInterestList"
  (configLanguageChangedEmitter)="configLanguageChanged($event)"
  (pointOfInterestAssociatedEmitter)="pointOfInterestAssociated($event)"
  (removePointOfInterestAssociationEmitter)="removePointOfInterestAssociation()">
</app-module-configuration>

<app-module-multilanguage-content
  [mediaList]="mediaList"
  [multiLanguageForm]="multiLanguageForm"
  [isPointOfInterestAssociated]="isPointOfInterestAssociated"
  [languagesSelected]="languagesSelected"
  [moduleTranslations]="moduleTranslations"
  [moduleType]="moduleType"
  [multiLanguageForm]="multiLanguageForm"
  [items]="trueFalseItems"
  [textSynthesis]="textSynthesis"
  (languageChangedEmitter)="setLanguage($event)"
  (saveTranslationEmitter)="saveTranslation()">
</app-module-multilanguage-content>

@switch (moduleType) {
  @case (enumModuleType.Gallery) {
    <app-module-gallery
      [mediaList]="mediaList"
      [mediaForm]="mediaForm"
      [items]="items"
      [associatedMediaList]="associatedMediaList"
      (associatedMediaEmitter)="associateMedia($event)"
      (deleteAssociatedMediaEmitter)="removeMedia($event)">
    </app-module-gallery>
  }
  @case (enumModuleType.Custom) {
    <app-module-custom
      [moduleCustomTemplates]="moduleCustomTemplates"
      [moduleForm]="moduleForm"
      >
    </app-module-custom>
  }
}
