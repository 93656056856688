import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Itinerary } from 'app/Models/itinerary';

@Component({
  selector: 'app-itinerary-list',
  templateUrl: './itinerary-list.component.html',
  styleUrls: ['./itinerary-list.component.css'],
})
export class ItineraryListComponent {
  @Input() listOfItineraries: Itinerary[] = [];

  @Output() deleteItineraryEmitter: EventEmitter<number> = new EventEmitter<number>();

  deleteItinerary(itineraryId: number) {
    this.deleteItineraryEmitter.emit(itineraryId);
  }
}
