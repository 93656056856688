import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { confirmDialogType } from 'app/Enumerations/confirm-dialog-type.enum';
import { entityType } from 'app/Enumerations/entity-type.enum';
import { Module } from 'app/Models/module';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css'],
})
export class ConfirmationPopupComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() confirmTypeAction!: number;
  @Input() entityTypeAction!: number;
  @Input() multipleSelection!: boolean;
  @Input() entityId?: number;

  // Media deletion
  modulesAssociatedToMedia: Module[] = [];
  modulesAssociatedAreLoading: boolean = true;

  @Output() confirmActionEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  enumDialogType = confirmDialogType;
  enumEntityType = entityType;

  constructor(private dataService: DataService,
    private authService: AuthService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entityId'] && this.entityId) {
      this.dataService.getEntitiesAssociatedToMedia(this.entityId).subscribe({
        next: (modules) => {
          this.modulesAssociatedToMedia = modules;
          this.modulesAssociatedAreLoading = false;
        },
        error: (error) => {
          console.log(error.message);
        }
      });
    }
  }

  closePopup() {
    this.modulesAssociatedAreLoading = true;
    this.confirmActionEmitter.emit(false);
  }

  confirmAction() {
    this.confirmActionEmitter.emit(true);
  }
  
  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
