<form [formGroup]="mediaForm">
  <div class="card-media" formArrayName="items">
    <div class="horizontal-display">
      <i aria-hidden="true" (mousedown)="start($event)" class="drag-drop-icon bi bi-grip-horizontal" title="Glisser et déposer pour réorganiser l'ordre des médias"> </i>
      <div class="horizontal-display" [formGroupName]="formArrayIndex">
        <!-- <div class="horizontal-display" [formGroupName]="mediaAssociation.order - 1"> -->
        <input #mediaInputLabel pInputText class="margin-right" formControlName="mediaName" />
        <!-- <p class="margin-right-2">{{ mediaAssociation.media.name }} </p> -->
        <p class="margin-right">{{ displayFileType(mediaAssociation.media.fileType) }}</p>
        @if (mediaAssociation.media.fileType === enumFileType.Image) {
          <p-checkbox [binary]="true" formControlName="is360" class="checkbox margin-left" [value]="mediaAssociation.is360"></p-checkbox>
          <p>360°</p>
        }
        <!-- @else if (mediaAssociation.media.fileType === enumFileType.Video) {
                <p-checkbox [binary]="true" formControlName="is360" class="checkbox margin-left" [value]="mediaAssociation.is360"></p-checkbox>
                <p>360°</p>
            } -->
      </div>
    </div>
    <div class="horizontal-display">
      <i tabIndex="0" aria-hidden="true" class="bi bi-eye action-buttons-item margin-right" title="Visualiser le média" (click)="showPopup()"> </i>
      <i
        tabIndex="0"
        aria-hidden="true"
        class="bi bi-x-lg action-buttons-item"
        title="Supprimer l'association du média"
        (click)="removeMedia(); $event.stopPropagation()"
        (keypress)="removeMedia(); $event.stopPropagation()">
      </i>
    </div>
    <!-- @if ($index != 0) {
        <div 
            class="horizontal-line">
        </div>
    } -->
  </div>
</form>
<!-- 
<app-media-association
    [visible]="isPopupVisible"
    [mediaList]="mediaList"
    [mediaVisualisationFromPage]="mediaVisualisationFromPage"
    [mediaToDisplay]="associatedMediaList[indexPopupOpened]"
    [fileUrl]="fileUrl"
    (closePopupEmitter)="closePopup()"
    (associatedMediaEmitter)="associateMedia($event)">

</app-media-association> -->
