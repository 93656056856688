<div class="body">
  <div class="page">
    <div class="header">
      @if (isCreation) {
        <h1>Création d'une activité</h1>
        @if (isFormVisible) {
          <button class="button" (click)="createModule()" title="Créer l'activité">Créer</button>
        } @else {
          <button class="button" [disabled]="typeModuleSelected === -1" (click)="showForm()" title="Choisir le type d'activité">Choisir</button>
        }
      } @else if (isModification && module) {
        <div>
          <div class="module-name-state">
            <h1 class="truncate-one-line">{{ module.identificationName }}</h1>
            <span
              class="module-state single-line"
              title="État de publication de l'activité"
              [ngClass]="module.state === enumPublishState.Published ? 'green-state' : module.state === enumPublishState.Modified ? 'orange-state' : 'red-state'">
              {{ getModuleState() | titlecase }}
            </span>
          </div>

          @if (module.publicationDate) {
            <p>Dernière publication: {{ module.publicationDate | date: "medium" }}</p>
          }
          @if (module.lastModificationDate) {
            <p>Dernière modification: {{ module.lastModificationDate | date: "medium" }}</p>
          }
        </div>

        <p-splitButton label="Sauvegarder" title="Sauvegarder l'activité" (onClick)="updateModule(false)" [model]="menuItems"></p-splitButton>
      } @else {
        <h1>Liste des activités</h1>
        <button class="button" (click)="navigate('module-management/type-selection')" title="Créer une nouvelle activité">Créer</button>
      }
    </div>
    
    @if (isCreation) {
      @if (isFormVisible) {
        <app-module-management-form
          [moduleType]="moduleType"
          [moduleForm]="moduleForm"
          [mediaForm]="mediaForm"
          [items]="items"
          [mediaList]="mediaList"
          [moduleCustomTemplates]="moduleCustomTemplates"
          [associatedMediaList]="associatedMediaList"
          [multiLanguageForm]="multiLanguageForm"
          [isPointOfInterestAssociated]="isPointOfInterestAssociated"
          [pointOfInterestList]="pointOfInterestList"
          [languagesSelected]="languagesSelected"
          [moduleTranslations]="moduleTranslations"
          [textSynthesis]="textSynthesis"
          (configLanguageChangedEmitter)="configLanguageChanged($event)"
          (pointOfInterestAssociatedEmitter)="pointOfInterestAssociated($event)"
          (languageChangedEmitter)="languageChanged($event)"
          (saveTranslationEmitter)="saveTranslation()"
          (associatedMediaEmitter)="associateMedia($event)"
          (deleteAssociatedMediaEmitter)="removeMedia($event)"
          (removePointOfInterestAssociationEmitter)="removePointOfInterestAssociation()">
        </app-module-management-form>
      } @else {
        <div class="cardContainer">
          <!-- TODO: remove aria-hidden -->
          <div
            tabIndex="0"
            aria-hidden="true"
            class="cardTypeModule"
            title="Galerie multimédia"
            [class.selected]="enumModuleType.Gallery === typeModuleSelected"
            (click)="setSelectedType(enumModuleType.Gallery)"
            (keypress)="setSelectedType(enumModuleType.Gallery)">
            <h3>{{ moduleTypeToString(enumModuleType.Gallery) }}</h3>
            <i class="bi bi-images"></i>
          </div>

          <div
            tabIndex="0"
            aria-hidden="true"
            class="cardTypeModule"
            title="Quiz vrai faux."
            [class.selected]="enumModuleType.QuizTrueFalse === typeModuleSelected"
            (click)="setSelectedType(enumModuleType.QuizTrueFalse)"
            (keypress)="setSelectedType(enumModuleType.QuizTrueFalse)">
            <h3>{{ moduleTypeToString(enumModuleType.QuizTrueFalse) }}</h3>
            <i class="bi bi-patch-question"></i>
          </div>

          <div
            tabIndex="0"
            aria-hidden="true"
            class="cardTypeModule"
            title="Quiz à quatre réponses."
            [class.selected]="enumModuleType.QuizMultipleChoice === typeModuleSelected"
            (click)="setSelectedType(enumModuleType.QuizMultipleChoice)"
            (keypress)="setSelectedType(enumModuleType.QuizMultipleChoice)">
            <h3>{{ moduleTypeToString(enumModuleType.QuizMultipleChoice) }}</h3>
            <i class="bi bi-play-btn"></i>
          </div>

          <!-- <div
            tabIndex="0"
            aria-hidden="true"
            class="cardTypeModule"
            title="Quiz à question incrémentale."
            [class.selected]="enumModuleType.QuizIncremental === typeModuleSelected"
            (click)="setSelectedType(enumModuleType.QuizIncremental)"
            (keypress)="setSelectedType(enumModuleType.QuizIncremental)">
            <h3>{{ moduleTypeToString(enumModuleType.QuizIncremental) }}</h3>
            <i class="bi bi-play-btn"></i>
          </div> -->

          <div
            tabIndex="0"
            aria-hidden="true"
            class="cardTypeModule"
            title="Activité spéciale."
            [class.selected]="enumModuleType.Custom === typeModuleSelected"
            (click)="setSelectedType(enumModuleType.Custom)"
            (keypress)="setSelectedType(enumModuleType.Custom)">
            <h3>{{ moduleTypeToString(enumModuleType.Custom) }}</h3>
            <i class="bi bi-gear"></i>
          </div>
        </div>
      }
    } @else if (isModification) {
      <app-module-management-form
        [moduleType]="moduleType"
        [moduleForm]="moduleForm"
        [mediaForm]="mediaForm"
        [items]="items"
        [mediaList]="mediaList"
        [associatedMediaList]="associatedMediaList"
        [moduleCustomTemplates]="moduleCustomTemplates"
        [multiLanguageForm]="multiLanguageForm"
        [isPointOfInterestAssociated]="isPointOfInterestAssociated"
        [pointOfInterestList]="pointOfInterestList"
        [languagesSelected]="languagesSelected"
        [moduleTranslations]="moduleTranslations"
        [textSynthesis]="textSynthesis"
        (configLanguageChangedEmitter)="configLanguageChanged($event)"
        (pointOfInterestAssociatedEmitter)="pointOfInterestAssociated($event)"
        (languageChangedEmitter)="languageChanged($event)"
        (saveTranslationEmitter)="saveTranslation()"
        (associatedMediaEmitter)="associateMedia($event)"
        (deleteAssociatedMediaEmitter)="removeMedia($event)"
        (removePointOfInterestAssociationEmitter)="removePointOfInterestAssociation()">
      </app-module-management-form>
    } @else {
      <p-table
        [value]="moduleList"
        [rows]="10"
        selectionMode="multiple"
        [(selection)]="selectedModuleList"
        [selectionPageOnly]="true"
        [paginator]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} à {last}, {totalRecords} activités"
        [rowsPerPageOptions]="[10, 20, 50]">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox title="Sélectionner toutes les activités de la page"></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="name">Nom <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="createdAt">Crée le <p-sortIcon field="createdAt"></p-sortIcon></th>
            <th>État pour publication</th>
            <th style="width: 8rem">Type</th>
            <th style="width: 4rem">
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-trash btn-remove-file"
                title="Supprimer les activités sélectionnées"
                (click)="showConfirmationPopup(0, true); $event.stopPropagation()"
                (keypress)="showConfirmationPopup(0, true); $event.stopPropagation()">
              </i>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-module>
          <tr class="table" (click)="navigate('module-management/' + module.id)" class="table">
            <td>
              <p-tableCheckbox title="Sélectionner cette activité" [value]="module" (click)="$event.stopPropagation()"></p-tableCheckbox>
            </td>
            <td>{{ module.identificationName }}</td>
            <td>{{ module.createdAt | date: "short" }}</td>
            <td>
              <app-completion-state class="margin-left" [completionState]="module.readyForPublication" [tooltipTrue]="'L\'activité est complète'" [tooltipFalse]="'L\'activité n\'est pas complète'">
              </app-completion-state>
            </td>
            <td>{{ moduleTypeToString(module.type) }}</td>
            <td>
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-trash btn-remove-file"
                title="Supprimer cette activité"
                (click)="showConfirmationPopup(module.id, false); $event.stopPropagation()"
                (keypress)="showConfirmationPopup(module.id, false); $event.stopPropagation()">
              </i>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">Aucune activité n'est enregistrée dans l'application.</td>
          </tr>
        </ng-template>
      </p-table>
    }
  </div>
</div>

<app-confirmation-popup
  [visible]="isConfirmationPopupVisible"
  [confirmTypeAction]="confirmTypeAction"
  [entityTypeAction]="entityTypeAction"
  [multipleSelection]="multipleDeletion"
  (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
