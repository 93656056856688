import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { displayValue, fileType } from 'app/Enumerations/file-type.enum';
import { MediaAssociation } from 'app/Models/module';

@Component({
  selector: 'app-associated-media-card',
  templateUrl: './associated-media-card.component.html',
  styleUrl: './associated-media-card.component.css',
})
export class AssociatedMediaCardComponent {
  @Input() formArrayIndex!: number;
  @Input() mediaAssociation!: MediaAssociation;

  @Output() dragStartedEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() showAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();

  //Forms
  @Input() mediaForm: FormGroup;
  @Input() items: FormArray | undefined;

  //Enumerations
  enumFileType = fileType;

  constructor(private fb: FormBuilder) {
    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  start(event: MouseEvent) {
    event.preventDefault();
    this.dragStartedEmitter.emit([event.clientY, event.offsetY, this.mediaAssociation.order]);
  }

  showPopup() {
    this.showAssociatedMediaEmitter.emit(this.mediaAssociation.order - 1);
  }

  removeMedia() {
    this.deleteAssociatedMediaEmitter.emit(this.mediaAssociation.order - 1);
  }
}
