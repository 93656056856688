import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../Data-Service/data.service';
import { HttpEventType } from '@angular/common/http';
import { Media } from 'app/Models/media';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  private filesToUpload = new BehaviorSubject<File[]>([]);
  private uploadProgressArray = new BehaviorSubject<number[]>([]);
  private uploadInProgress = new BehaviorSubject<boolean>(false);
  private uploadFinished = new BehaviorSubject<boolean>(false);
  private visiblePopup = new BehaviorSubject<boolean>(false);
  private newMedia = new BehaviorSubject<Media|null>(null);

  uploadProgressArray$ = this.uploadProgressArray.asObservable();
  filesToUpload$ = this.filesToUpload.asObservable();
  uploadInProgress$ = this.uploadInProgress.asObservable();
  uploadFinished$ = this.uploadFinished.asObservable();
  visiblePopup$ = this.visiblePopup.asObservable();
  newMedia$ = this.newMedia.asObservable();

  uploadStarted: boolean = false;

  allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'audio/mpeg', 'audio/aiff', 'audio/wav', 'video/quicktime', 'video/mp4'];

  uploadedFilesCount: number = 0;

  constructor(
    private dataService: DataService) { }
  
  setfiles(files: File[]) {
    this.filesToUpload.next(files);
  }

  uploadFiles() {
    this.uploadInProgress.next(true);
    this.uploadProgressArray.next(Array<number>(this.filesToUpload.value.length).fill(0));
    this.visiblePopup.next(true);
    this.uploadRecursive(0);
  }

  uploadRecursive(fileIndex: number) {
    const fd = new FormData();
    fd.append('file', this.filesToUpload.value[fileIndex]);
    this.dataService.uploadMedia(fd).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          this.uploadProgressArray.value[fileIndex] = Math.trunc((event.loaded / event.total) * 100);
        }
        if (event.type === HttpEventType.Response) {
          this.uploadedFilesCount++;
          this.newMedia.next(event.body);
          this.newMedia.next(null);
          if (this.uploadedFilesCount === this.filesToUpload.value.length) {
            this.resetUpload();
          } else {
            this.uploadRecursive(this.uploadedFilesCount);
          }
        }
      },
      error: (error) => {
        console.log('error ', error.error);
        console.log("Erreur lors de l'upload, ", error.message);
        this.uploadedFilesCount++;
        if (this.uploadedFilesCount == this.filesToUpload.value.length) {
          this.resetUpload();
        } else {
          this.uploadRecursive(this.uploadedFilesCount);
        }
      },
    });
  }

  resetUpload() {
    this.filesToUpload.next([]);
    this.uploadInProgress.next(false);
    this.uploadFinished.next(true);
    this.uploadedFilesCount = 0;
  }

  setVisiblePopup(isVisible: boolean) {
    this.visiblePopup.next(isVisible);
  }

}
