import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ItineraryPublication } from 'app/Models/publication';
import { AuthService } from 'app/Services/Auth-Service/auth.service';

@Component({
  selector: 'app-publication-popup',
  templateUrl: './publication-popup.component.html',
  styleUrl: './publication-popup.component.css',
})
export class PublicationPopupComponent {
  @Input() visible: boolean = false;
  @Input() publication: ItineraryPublication = new ItineraryPublication();

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();

  showPointOfInterests: boolean = true;
  showModules: boolean[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  closePopup() {
    this.closePopupEmitter.emit();
  }

  // TODO: I think we should show only POI and Module that have issues
  // and collapse/unshow the others
  setShowPointOfInterests(value: boolean) {
    if (!value) {
      this.publication.pointOfInterests.forEach(() => {
        this.showModules.push(true);
      });
    }
  }

  navigate(url: string) {
    const projectName = this.authService.getProjectName();
    this.router.navigateByUrl(`/${projectName}/${url}`);
  }
}
