import { Injectable } from '@angular/core';
import { Module, Question } from 'app/Models/module';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModuleService {
  private trueFalseQuestions = new BehaviorSubject<Question[]>([]);
  // This is currently only used to retrieve the id in
  // ModuleConfigurationComponent.modifyLanguageInService
  // We should maybe use this instead of the multiple @output @input in modules component
  // A huge refactor might be needed to avoid the current complexity
  private module = new BehaviorSubject<Module>(new Module());
  listOfTrueFalseQuestions = this.trueFalseQuestions.asObservable();
  moduleObservable = this.module.asObservable();

  constructor() {}

  resetTrueFalseQuestions() {
    this.trueFalseQuestions.next([]);
  }

  setQuizQuestions(questions: Question[]) {
    this.trueFalseQuestions.next(questions);
  }

  getQuizQuestions(): Question[] {
    return this.trueFalseQuestions.getValue();
  }

  setModule(module: Module) {
    this.module.next(module);
  }

  getModule(): Module {
    return this.module.getValue();
  }

  getModuleType(): number {
    return this.module.getValue().type;
  }

  resetModuleImageSynthesis() {
    const module = this.module.getValue();
    module.mediaSynthesis = undefined;
    module.mediaSynthesisId = undefined;
    this.module.next(module);
  }
}
