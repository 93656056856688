import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from 'app/Models/language';

@Component({
  selector: 'app-add-language-popup',
  templateUrl: './add-language-popup.component.html',
  styleUrl: './add-language-popup.component.css',
})
export class AddLanguagePopupComponent {
  @Input() visible: boolean = false;
  @Input() availableLanguages: Language[] = [];
  @Input() selectedLanguages: Language[] = [];
  @Input() languageIdToDelete: number = 0;

  @Output() confirmActionEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();

  selectedLanguageArray: boolean[] = [];

  selectLanguage(index: number) {
    this.selectedLanguageArray[index] = !this.selectedLanguageArray[index];
  }

  changeLanguageConfiguration() {
    if (this.languageIdToDelete != 0) {
      this.confirmActionEmitter.emit([this.languageIdToDelete]);
    } else {
      const newLanguages: number[] = [];

      this.selectedLanguageArray.forEach((isSelected, index) => {
        if (isSelected) {
          newLanguages.push(this.availableLanguages[index].id);
        }
      });

      this.confirmActionEmitter.emit(newLanguages);
      this.selectedLanguageArray = [];
    }
  }

  getLanguageIndexById(languageId: number): number {
    return this.selectedLanguages.map((l) => l.id).indexOf(languageId);
  }

  deleteLanguage() {
    this.confirmActionEmitter.emit([this.languageIdToDelete]);
  }

  closePopup() {
    this.confirmActionEmitter.emit([]);
  }
}
