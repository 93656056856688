import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { moduleType } from 'app/Enumerations/module-type.enum';
import { Language } from 'app/Models/language';
import { Media } from 'app/Models/media';
import { ModuleTranslation, MediaAssociation, ModuleCustomTemplate } from 'app/Models/module';
import { PointOfInterest } from 'app/Models/point-of-interest';

@Component({
  selector: 'app-module-management-form',
  templateUrl: './module-management-form.component.html',
  styleUrl: './module-management-form.component.css',
})
export class ModuleManagementFormComponent {
  @Input() moduleType!: number;

  @Input() pointOfInterestList: PointOfInterest[] = [];
  @Input() associatedPointOfInterest?: PointOfInterest;
  @Input() languagesSelected: Language[] = [];
  @Input() moduleTranslations: ModuleTranslation[] = [];
  @Input() isPointOfInterestAssociated: boolean = false;

  @Input() mediaList: Media[] = [];
  @Input() associatedMediaList: MediaAssociation[] = [];

  @Input() moduleCustomTemplates: ModuleCustomTemplate[] = [];

  @Output() pointOfInterestAssociatedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() removePointOfInterestAssociationEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveTranslationEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() configLanguageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();

  @Output() associatedMediaEmitter: EventEmitter<MediaAssociation> = new EventEmitter<MediaAssociation>();
  @Output() deleteAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();

  // Forms
  @Input() moduleForm: FormGroup;
  @Input() multiLanguageForm: FormGroup;
  @Input() mediaForm: FormGroup;
  @Input() items: FormArray | undefined;
  @Input() textSynthesis: FormControl = new FormControl('');
  trueFalseQuizForm!: FormGroup;
  trueFalseItems: FormArray | undefined;

  //Enumerations
  enumModuleType = moduleType;

  constructor(private fb: FormBuilder) {
    this.moduleForm = this.fb.group({
      moduleLabel: ['', Validators.required],
      moduleType: [''],
      associatedPOI: [''],
      languages: [''],
      moduleCustomTemplateId: [''],
      languagesFromPOI: [''],
    });

    this.multiLanguageForm = this.fb.group({
      moduleName: [''],
      currentLanguage: [''],
    });

    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });

    // this.trueFalseQuizForm = this.fb.group({
    //   trueFalseItems: new FormArray([])
    // });
  }

  pointOfInterestAssociated(pointOfInterestId: number) {
    this.pointOfInterestAssociatedEmitter.emit(pointOfInterestId);
  }

  setLanguage(languageSelected: number) {
    this.languageChangedEmitter.emit(languageSelected);
  }

  associateMedia(media: MediaAssociation) {
    this.associatedMediaEmitter.emit(media);
  }

  removeMedia(index: number) {
    this.deleteAssociatedMediaEmitter.emit(index);
  }

  saveTranslation() {
    this.saveTranslationEmitter.emit();
  }

  configLanguageChanged(languageId: number) {
    this.configLanguageChangedEmitter.emit(languageId);
  }

  removePointOfInterestAssociation() {
    this.removePointOfInterestAssociationEmitter.emit();
  }
}
