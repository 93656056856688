export class Project {
    id!: number;
    identificationName!: string;
    isModuleUsedInProject?: boolean;

    constructor(id: number, identificationName: string) {
      this.id = id;
      this.identificationName = identificationName;
    }
  }
  