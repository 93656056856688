import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { moduleTypeToString, moduleType } from 'app/Enumerations/module-type.enum';
import { LanguageListMock } from 'app/Mocks/list-of-languages';
import { Language } from 'app/Models/language';
import { AnswerTranslation, QuestionTranslation } from 'app/Models/module';
import { PointOfInterest } from 'app/Models/point-of-interest';
import { ModuleService } from 'app/Services/Module-Service/module.service';
import { MessageService } from 'primeng/api';
import { MultiSelectChangeEvent } from 'primeng/multiselect';

@Component({
  selector: 'app-module-configuration',
  templateUrl: './module-configuration.component.html',
  styleUrl: './module-configuration.component.css',
})
export class ModuleConfigurationComponent {
  @Input() pointOfInterestList: PointOfInterest[] = [];

  @Output() pointOfInterestAssociatedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() configLanguageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() removePointOfInterestAssociationEmitter: EventEmitter<void> = new EventEmitter<void>();

  languagesList: Language[] = LanguageListMock;

  // Forms
  @Input() moduleForm: FormGroup;

  //Enumerations
  enumModuleType = moduleType;

  inputLength = inputLength;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private moduleService: ModuleService,
  ) {
    this.moduleForm = this.fb.group({
      moduleLabel: ['', [Validators.maxLength(inputLength.maxModuleLabel), Validators.required]],
      moduleType: [''],
      associatedPOI: [''],
      languages: [''],
      languagesFromPOI: [''],
    });
  }

  pointOfInterestAssociated(pointOfInterestId: number) {
    this.pointOfInterestAssociatedEmitter.emit(pointOfInterestId);
    const languagesPoi = this.pointOfInterestList.filter(p => p.id === pointOfInterestId)[0].languageIds;
    for (let i = 0; i < languagesPoi.length; i++) {
      this.modifyLanguageInService(languagesPoi[i], true);
    }
  }

  configurationLanguageChange(event: MultiSelectChangeEvent) {
    if (event.itemValue != null) {
      const language = event.itemValue as Language;

      const indexPointOfInterest = this.pointOfInterestList.map((poi) => poi.id).indexOf(this.moduleForm.get(['associatedPOI'])?.value);
      
      if (indexPointOfInterest != -1 && this.pointOfInterestList[indexPointOfInterest].languageIds.indexOf(language.id) != -1) {
        this.messageService.clear();
        this.messageService.add({ severity: 'warn', summary: 'Suppression non autorisée', detail: 'Cette langue est héritée de l\'étape associé.' });
        this.moduleForm.get(['languages'])?.setValue(this.setLanguagesForm(this.moduleForm.get(['languages'])?.value, language.id));
      } else {
        this.configLanguageChangedEmitter.emit(language.id);

        const moduleType = this.moduleForm.get(['moduleType'])?.value;
        //Add or remove language in service
        if (moduleType === this.enumModuleType.QuizTrueFalse || moduleType === this.enumModuleType.QuizMultipleChoice || moduleType === this.enumModuleType.QuizIncremental) {
          this.modifyLanguageInService(language.id, false);
        }
      }
      //TODO: Clean this up
    } else if (event.value.length == 0) {
      // this.languageChangedEmitter.emit('emptyList');
      // console.log('empty list');
    } else {
      // console.log('list complete');
      // this.languageChangedEmitter.emit('fullList');
    }

    //TODO: finish the implementation of adding all the languages to the itinerary

    // this.languageChangedEmitter.emit(event.itemValue);
  }

  setLanguagesForm(languageIds: number[], newLanguageId: number): number[] {
    languageIds.push(newLanguageId);
    languageIds.sort();

    return languageIds;
  }

  removePointOfInterestAssociation() {
    this.removePointOfInterestAssociationEmitter.emit();
  }

  displayModuleType(): string {
    return moduleTypeToString(this.moduleForm.get(['moduleType'])?.value);
  }

  modifyLanguageInService(languageId: number, languageFromPoi: boolean) {
    const quizQuestions = this.moduleService.getQuizQuestions();
    const moduleType = this.moduleForm.get(['moduleType'])?.value;

    if (quizQuestions.length > 0 || moduleType === this.enumModuleType.QuizIncremental) {
      const indexLanguage = quizQuestions[0].questionTranslations.map((e) => e.languageId).indexOf(languageId);

      if (indexLanguage === -1) {
        quizQuestions.forEach((question) => {
          const questionTranslation: QuestionTranslation = {
            translatedEntityId: 0, //TODO: check if it should be 0
            languageId: languageId,
            question: '',
            answerExplanation: ''
          };
          question.questionTranslations.push(questionTranslation);

          question.answers.forEach((answer) => {
            answer.answerTranslations.push(new AnswerTranslation(0, languageId, '', ''));
          });
        });
      } else if (!languageFromPoi) {
        quizQuestions.forEach((question) => {
          question.questionTranslations.splice(indexLanguage, 1);

          question.answers.forEach((answer) => {
            answer.answerTranslations.splice(indexLanguage, 1);
          });
        });
      }
      this.moduleService.setQuizQuestions(quizQuestions);
    }
  }

  countLabelCharacters(): string {
    return this.moduleForm.get(['moduleLabel'])?.value.length;
  }
}
