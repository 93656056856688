import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin } from 'app/Models/userLogin';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
  providers: [AuthService],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  token: string = '';
  isLogging: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.authService.userIsLogging.subscribe((result) => {
      this.isLogging = result;
    });
  }

  login() {
    this.authService.setUserIsLogging(true);
    const val = this.loginForm.value;
    if (val.username && val.password) {
      const userLogin = new UserLogin();
      userLogin.username = val.username;
      userLogin.password = val.password;
      this.authService.login(userLogin);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Les identifiants renseignés ne sont pas valides.' }); 
      this.isLogging = false;
    }
  }

  logout() {
    this.authService.logout();
  }

  checkLogin(): void {
    this.authService.isLoggedIn();
  }

  checkLogout(): void {
    this.authService.isLoggedOut();
  }
}
