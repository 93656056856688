<div class="flex justify-content-center">
    <p-dialog header="Header" [dismissableMask]="true" [modal]="true" [(visible)]="visible" [style]="{ width: '50vw' }" (onHide)="closePopup()">
      <ng-template pTemplate="header">
        <h2 class="text-xl font-bold">Confirmer</h2>
      </ng-template>
      
      @if (addAssociation) {
        <p>Vous avez choisi d'ajouter ces associations:</p>
      }
      @for (association of moduleCustomAssociations; track $index) {
        @if (association.isAssociated) {
          <p class="margin-left-small">{{ displayModuleAssociation(association) }}</p>
        }
      }
      
      @if (removeAssociation) {
        <p>Vous avez choisi de supprimer ces associations:</p>
      }
      @for (association of moduleCustomAssociations; track $index) {
        @if (!association.isAssociated) {
          <p class="margin-left-small">{{ displayModuleAssociation(association) }}</p>
        }
      }
  
      <ng-template pTemplate="footer">
        <button class="button confirm-action-button" (click)="confirmAction()">
            <span title="Modifier">Confirmer</span>
        </button>
        <button class="button" (click)="closePopup()" title="Annuler l'action">Annuler</button>
      </ng-template>
    </p-dialog>
  </div>
  