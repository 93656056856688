import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { ItineraryTranslation } from 'app/Models/itineraryDetail';
import { Language } from 'app/Models/language';

@Component({
  selector: 'app-itinerary-multilanguage-content',
  templateUrl: './itinerary-multilanguage-content.component.html',
  styleUrls: ['./itinerary-multilanguage-content.component.css'],
})
export class ItineraryMultilanguageContentComponent {
  @Input() multiLanguageForm!: FormGroup;
  @Input() languagesSelected: Language[] = [];
  @Input() itineraryTranslation: ItineraryTranslation[] = [];
  @Input() multilanguageContentDropdownDisabled: boolean = false;

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveLanguageEmitter: EventEmitter<void> = new EventEmitter<void>();

  inputLength = inputLength;

  constructor(private fb: FormBuilder) {
    this.multiLanguageForm = this.fb.group({
      itineraryName: [''],
      currentLanguage: [''],
      details: [''],
    });
  }

  setLanguage(languageSelected: number) {
    this.languageChangedEmitter.emit(languageSelected);
  }

  saveLanguage() {
    this.saveLanguageEmitter.emit();
  }

  showLanguageState(languageId: number): boolean {
    const indexLanguage = this.itineraryTranslation.map((translation) => translation.languageId).indexOf(languageId);

    return !(this.itineraryTranslation[indexLanguage].name == '' || this.itineraryTranslation[indexLanguage].details == '');
  }

  countNameCharacters(): string {
    return this.multiLanguageForm.get(['itineraryName'])?.value.length;
  }

  countDetailsCharacters(): string {
    return this.multiLanguageForm.get(['details'])?.value.length;
  }
}
