import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { LanguageListMock } from 'app/Mocks/list-of-languages';
import { Language } from 'app/Models/language';

@Component({
  selector: 'app-itinerary-configuration',
  templateUrl: './itinerary-configuration.component.html',
  styleUrls: ['./itinerary-configuration.component.css'],
})
export class ItineraryConfigurationComponent {
  @Input() informationsForm!: FormGroup;

  @Input() selectedLanguages: Language[] = [];

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();

  visibleLanguages: boolean = false;
  languagesList: Language[] = LanguageListMock;
  languageIdToDelete: number = 0;

  inputLength = inputLength;

  constructor(private formBuilder: FormBuilder) {
    this.informationsForm = this.formBuilder.group({
      itineraryLabel: ['', Validators.required],
      languages: [''],
      isLocated: [''],
    });
  }

  // configurationLanguageChange(event: MultiSelectChangeEvent) {
  //   this.informationsForm.get(['languages'])?.setValue(this.informationsForm.get(['languages'])?.value.sort());

  //   console.log(event.itemValue);

  //   if (event.itemValue != null) {
  //     const language = event.itemValue as Language;

  //     this.languageChangedEmitter.emit(language.id);
  //   }
  //   // TODO: check why we emited emptyList and fullList. Was this used somwhere?
  //   // A full solution search doesn't return result, so it could be safe to delete
  //   else if (event.value.length == 0) {
  //     this.languageChangedEmitter.emit(-1);
  //     //this.languageChangedEmitter.emit('emptyList');
  //   } else {
  //     this.languageChangedEmitter.emit(-2);
  //     //this.languageChangedEmitter.emit('fullList');
  //   }

  //   //TODO: finish the implementation of adding all the languages to the itinerary

  //   // this.languageChangedEmitter.emit(event.itemValue);
  // }

  languagesChanged(languageIds: number[]) {
    if (languageIds.length == 0) {
      // console.log('liste vide');
    } else if (languageIds[0] == -1) {
      // console.log('juste fermer la liste');
    } else {
      languageIds.forEach((id) => {
        // const index = this.languagesList.map(l => l.id).indexOf(id);
        this.languageChangedEmitter.emit(id);
        // this.selectedLanguages.push(this.languagesList[index]);
      });
    }
    this.closePopup();
  }

  showPopupLanguageDeletion(idLanguage: number) {
    this.languageIdToDelete = idLanguage;
    this.visibleLanguages = true;
  }

  closePopup() {
    this.visibleLanguages = false;
    this.languageIdToDelete = 0;
  }
  
  countLabelCharacters(): string {
    if (this.informationsForm.get(['itineraryLabel'])?.value) {
      return this.informationsForm.get(['itineraryLabel'])?.value.length;
    } else {
      return '0';
    }
  }
}
