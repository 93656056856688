import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModuleCustomTemplate } from 'app/Models/module';
import { ModuleService } from 'app/Services/Module-Service/module.service';

@Component({
  selector: 'app-module-custom',
  templateUrl: './module-custom.component.html',
  styleUrl: './module-custom.component.css'
})
export class ModuleCustomComponent {
  @Input() moduleForm: FormGroup;
  @Input() moduleCustomTemplates: ModuleCustomTemplate[] = [];

  constructor(private moduleService: ModuleService, private fb: FormBuilder) {
    this.moduleForm = this.fb.group({
      moduleCustomTemplateId: [''],
    });
  }

  selectModuleTemplate(id: number) {
    const module = this.moduleService.getModule();
    module.moduleCustomTemplateId = id;
  }
}
