<div class="body">
  <div class="page">
    <div class="header">
      <h1>Liste des parcours</h1>
      <a routerLink="/{{ this.getProjectName() }}/itinerary-management/new">
        <button class="button" title="Créer un nouveau parcours">Créer</button>
      </a>
    </div>

    <app-itinerary-list [listOfItineraries]="listOfItineraries" (deleteItineraryEmitter)="deleteItinerary($event)"> </app-itinerary-list>

    @if (listOfItineraries.length === 0 && dataReceived === true) {
      <p>Aucun parcours n'est enregistré dans l'application.</p>
    }
  </div>
</div>
