<div class="card">
  <form [formGroup]="multiLanguageForm">
    <div class="header">
      <div class="horizontal-display">
        <h2 title="Cette section sert à configurer le contenu de l'étape qui sera visible par l'utilisateur.">Contenu de l'étape<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>
        @for (language of languagesSelected; track language) {
          <div class="language-state" [ngClass]="showLanguageState(language.id) ? 'green-state' : 'red-state'" [title]="language.identificationName">
            <span>{{ language.code | uppercase }}</span>
          </div>
        }
      </div>
    </div>

    @if (LanguageInfosPointOfInterest.length === 0) {
      <p>Aucune langue n'est associée au parcours, veuillez en ajouter une pour pouvoir ajouter du contenu à l'étape</p>
    } @else {
      <div class="horizontal-display">
        <div class="vertical-display">
          <div class="form-field">
            <label htmlFor="pointOfInterestName">Langue en cours de modification</label>
            <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p>
            <p-dropdown [options]="languagesSelected" optionValue="id" optionLabel="identificationName" formControlName="currentLanguage" (onChange)="setLanguage($event.value)"> </p-dropdown>
          </div>
          <div class="form-field">
            <span class="">
              <label htmlFor="pointOfInterestName">Nom de l'étape</label>
              <p class="field-explanation">Comment souhaitez-vous nommer votre parcours</p>
              <input pInputText id="pointOfInterestName" formControlName="pointOfInterestName" (change)="saveLanguage()" [maxlength]="inputLength.maxPointOfInterestName" />
              <small class="char-count">
                ({{ countNameCharacters() }}/{{ inputLength.maxPointOfInterestName }})
              </small>
            </span>
          </div>
        </div>
        <div class="vertical-display form-field">
          <span class="">
            <label for="details ">Détails de l'étape</label>
            <p class="field-explanation">Renseignez les informations générales de l'étape</p>
            <textarea id="details" rows="5" pInputTextarea formControlName="details" [autoResize]="true" (change)="saveLanguage()" [maxlength]="inputLength.maxPointOfInterestDetails"> </textarea>
            <small class="char-count">
              ({{ countDetailsCharacters() }}/{{ inputLength.maxPointOfInterestDetails }})
            </small>
          </span>
        </div>
      </div>
    }
  </form>
</div>
