import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { inputLength } from 'app/ConfigVariables/input-length';
import { confirmDialogType } from 'app/Enumerations/confirm-dialog-type.enum';
import { entityType } from 'app/Enumerations/entity-type.enum';
import { fileType } from 'app/Enumerations/file-type.enum';
import { moduleTypeToString, moduleType } from 'app/Enumerations/module-type.enum';
import { getPublishStateValue as getPublishStateValue, publishState } from 'app/Enumerations/publish-state.enum';
import { responseCode } from 'app/Enumerations/response-code.enum';
import { LanguageListMock } from 'app/Mocks/list-of-languages';
import { Language } from 'app/Models/language';
import { Media } from 'app/Models/media';
import { ModuleTranslation, MediaAssociation, Module, ModuleCustomTemplate } from 'app/Models/module';
import { PointOfInterest } from 'app/Models/point-of-interest';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';
import { ModuleService } from 'app/Services/Module-Service/module.service';
import { MenuItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-module-management-page',
  templateUrl: './module-management-page.component.html',
  styleUrl: './module-management-page.component.css',
})
export class ModuleManagementPageComponent implements OnInit, OnDestroy {
  isCreation: boolean = false;
  isModification: boolean = false;
  isFormVisible: boolean = false;
  isPointOfInterestAssociated: boolean = false;

  selectedLanguageId: number = -1;

  module?: Module;
  moduleList: Module[] = [];
  selectedModuleList: Module[] = [];

  mediaList: Media[] = [];

  moduleType: moduleType = moduleType.Undefined;
  typeModuleSelected: number = -1;

  pointOfInterestList: PointOfInterest[] = [];
  associatedPointOfInterest?: PointOfInterest;

  languagesSelected: Language[] = [];
  moduleTranslations: ModuleTranslation[] = [];

  associatedMediaList: MediaAssociation[] = [];

  moduleCustomTemplates: ModuleCustomTemplate[] = [];

  // Forms
  moduleForm: FormGroup;
  multiLanguageForm: FormGroup;
  mediaForm: FormGroup;
  items: FormArray | undefined;
  textSynthesis: FormControl = new FormControl('');

  // Confirmation dialog attributes
  isConfirmationPopupVisible: boolean = false;
  confirmTypeAction!: number;
  entityTypeAction!: number;
  moduleIdToProcess!: number;
  multipleDeletion!: boolean;

  //Enumerations
  enumModuleType = moduleType;
  enumPublishState = publishState;
  enumDialogType = confirmDialogType;
  enumEntityType = entityType;
  enumResponseCode = responseCode;

  //splitButton attributes
  menuItems: MenuItem[] = [];

  // Mock for languages List
  languagesList: Language[] = LanguageListMock;

  inputLength = inputLength;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private moduleService: ModuleService,
  ) {
    this.moduleForm = this.fb.group({
      moduleLabel: ['', Validators.required],
      moduleType: [''],
      associatedPOI: [''],
      languages: [''],
      moduleCustomTemplateId: [''],
      languagesFromPOI: [],
    });

    this.multiLanguageForm = this.fb.group({
      moduleName: [''],
      currentLanguage: [''],
    });

    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  ngOnInit() {
    const routePath = this.route.snapshot.routeConfig?.path;
    const moduleIdFromRoute = this.route.snapshot.paramMap.get('id');

    if (routePath != null && routePath === `:projectName/module-management`) {
      this.isCreation = false;

      this.dataService.getModules().subscribe({
        next: (modules) => {
          this.moduleList = modules;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else if (routePath != null && routePath === `:projectName/module-management/new-module`) {
      this.isCreation = true;
      this.isFormVisible = true;
      this.module = new Module();

      this.typeModuleSelected = history.state.moduleType;
      this.module.type = this.typeModuleSelected;
      this.moduleType = this.typeModuleSelected;
      this.moduleForm.get(['moduleType'])?.setValue(this.typeModuleSelected);

      this.moduleService.setModule(this.module);

      this.dataService.getModuleCustomTemplates().subscribe({
        next: (moduleCustomTemplates) => {
          this.moduleCustomTemplates = moduleCustomTemplates;
        }
      })

      this.dataService.getAllPointsOfInterest().subscribe({
        next: (pointsOfInterest) => {
          this.pointOfInterestList = pointsOfInterest;
        },
        error: (error) => {
          console.log(error.message);
        },
      });

      this.dataService.getMedias().subscribe({
        next: (medias) => {
          this.mediaList = medias;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else if (routePath != null && routePath === `:projectName/module-management/type-selection`) {
      this.isCreation = true;
    } else if (moduleIdFromRoute != null) {
      this.isCreation = false;
      this.isModification = true;

      this.dataService.getModule(moduleIdFromRoute).subscribe({
        next: (module) => {
          
          this.module = module;
          this.moduleType = module.type;
          this.moduleTranslations = module.moduleTranslations;
          this.moduleService.setModule(this.module);

          if (module.type === this.enumModuleType.Gallery) {
            this.setMediasAssociated(module.mediaAssociations);
          }

          if (module.type === this.enumModuleType.Custom) {
            this.dataService.getModuleCustomTemplates().subscribe({
              next: (moduleCustomTemplates) => {
                this.moduleCustomTemplates = moduleCustomTemplates;
              }
            });

            this.moduleForm.get(['moduleCustomTemplateId'])?.setValue(module.moduleCustomTemplateId);
          }

          this.moduleForm.get(['moduleLabel'])?.setValue(module.identificationName);
          this.moduleForm.get(['moduleType'])?.setValue(module.type);
          this.moduleForm.get(['associatedPOI'])?.setValue(module.pointOfInterestId);
          if (module.moduleTranslations) {
            this.moduleForm.get(['languages'])?.setValue(this.setLanguagesForm(module.moduleTranslations));
          }

          if (module.pointOfInterestId != null) {
            this.isPointOfInterestAssociated = true;
          }

          if (this.moduleTranslations.length > 0) {
            this.languagesSelected = this.setLanguagesSelected();
            this.selectedLanguageId = this.moduleTranslations[0].languageId;
            this.multiLanguageForm.get(['currentLanguage'])?.setValue(this.selectedLanguageId);
            this.multiLanguageForm.get(['moduleName'])?.setValue(this.moduleTranslations[0].name);
          }
          
          if ((module.type === this.enumModuleType.QuizTrueFalse || module.type === this.enumModuleType.QuizMultipleChoice || module.type === this.enumModuleType.QuizIncremental) && module.questions) {
            this.moduleService.setQuizQuestions(module.questions);
          }

          this.dataService.getAllPointsOfInterest().subscribe({
            next: (pointsOfInterest) => {
              this.pointOfInterestList = pointsOfInterest;
            },
            error: (error) => {
              console.log(error.message);
            },
          });

          this.menuItems = [
            {
              label: 'Sauvegarder et dupliquer',
              title: 'Sauvegarder et dupliquer l\'activité',
              command: () => {
                this.updateModule(true);
              },
            },
            {
              label: 'Supprimer',
              title: 'Supprimer l\'activité',
              command: () => {
                this.showConfirmationPopup(module.id, false);
              },
            },
          ];
        },
        error: (error) => {
          console.log(error.message);
        },
      });

      this.dataService.getMedias().subscribe({
        next: (medias) => {
          this.mediaList = medias;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    }
  }

  ngOnDestroy() {
    this.moduleService.resetTrueFalseQuestions();
    this.moduleService.resetModuleImageSynthesis();
  }

  getModuleState() {
    if (this.module) {
      return getPublishStateValue(this.module?.state);
    } else {
      return '';
    }
  }

  moduleTypeToString(type: number): string {
    return moduleTypeToString(type);
  }

  setSelectedType(moduleType: number) {
    if (this.typeModuleSelected === moduleType) {
      this.typeModuleSelected = -1;
    } else {
      this.typeModuleSelected = moduleType;
    }
  }

  showForm() {
    this.isFormVisible = true;
    this.moduleType = this.typeModuleSelected;
    this.moduleForm.get(['moduleType'])?.setValue(this.typeModuleSelected);
    this.router.navigateByUrl(`${this.authService.getProjectName()}/module-management/new-module`, {
      state: {
        moduleType: this.typeModuleSelected,
      },
    });
  }

  duplicateModule() {
    if (!this.isCreation && this.module) {
      let moduleName = this.moduleForm.get(['moduleLabel'])?.value;
      moduleName = moduleName + '-Copie';
      this.moduleForm.get(['moduleLabel'])?.setValue(moduleName);
      this.module.lastModificationDate = undefined;
      this.module.publicationDate = undefined;

      // this.module.identificationName = moduleName;

      const mediaAssociationList = this.updateMediaAssociations();

      const newModule: Module = {
        id: 0,
        pointOfInterestId: this.moduleForm.get(['associatedPOI'])?.value,
        identificationName: moduleName,
        state: this.enumPublishState.NotPublished,
        order: -1,
        type: this.moduleType, //TODO: change with the value in the form when multiple type will be available
        readyForPublication: false,
        moduleTranslations: this.moduleTranslations,
        mediaAssociations: mediaAssociationList,
        questions: this.moduleService.getQuizQuestions(),
        moduleCustomTemplateId: this.moduleService.getModule().moduleCustomTemplateId
      };

      if (this.moduleType == this.enumModuleType.Gallery) {
        this.dataService.createModuleGallery(newModule).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été dupliquée.' });
            if (this.module) {
              this.module.identificationName = module.identificationName;
              this.module.id = module.id;
            }

            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.QuizTrueFalse) {
        this.dataService.createModuleQuizTrueFalse(newModule).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été dupliquée.' });
            if (this.module) {
              this.module.identificationName = module.identificationName;
              this.module.id = module.id;
            }
            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.QuizMultipleChoice) {
        this.dataService.createModuleQuizMultipleChoice(newModule).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été dupliquée.' });
            if (this.module) {
              this.module.identificationName = module.identificationName;
              this.module.id = module.id;
            }

            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.QuizIncremental) {
        this.dataService.createModuleQuizIncremental(newModule).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été dupliqué.' });
            if (this.module) {
              this.module.identificationName = module.identificationName;
              this.module.id = module.id;
            }

            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      }
    }
  }

  createModule() {
    if (this.moduleForm.valid) {
      this.saveTranslation();

      const mediaAssociationList = this.updateMediaAssociations();

      // When we refactor the moduleService, we won't need to create a new module there
      // and actually only use moduleService.GetModule()?
      const newModule: Module = {
        id: 0,
        pointOfInterestId: this.moduleForm.get(['associatedPOI'])?.value,
        identificationName: this.moduleForm.get(['moduleLabel'])?.value,
        state: this.enumPublishState.NotPublished,
        order: -1,
        type: this.moduleType, //TODO: change with the value in the form when multiple type will be available
        readyForPublication: false,
        moduleTranslations: this.moduleTranslations,
        mediaAssociations: mediaAssociationList,
        mediaSynthesisId: this.moduleService.getModule().mediaSynthesis?.id,
        questions: this.moduleService.getQuizQuestions(),
        moduleCustomTemplateId: this.moduleService.getModule().moduleCustomTemplateId
      };

      if (!this.moduleForm.get(['associatedPOI'])?.value) {
        newModule.pointOfInterestId = null;
      }

      const module = structuredClone(newModule);

      console.log('create ', module);
      
      if (this.moduleType === this.enumModuleType.QuizMultipleChoice || this.moduleType === this.enumModuleType.QuizTrueFalse || this.moduleType === this.enumModuleType.QuizIncremental) {
        module.questions.forEach((question) => {
          question.mediaId = question.media?.id;
          question.media = undefined;
          if (this.moduleType === this.enumModuleType.QuizMultipleChoice || this.moduleType === this.enumModuleType.QuizIncremental) {
            question.answers.forEach((answer) => {
              answer.mediaId = answer.media?.id;
              answer.media = undefined;
            });
          }
        });
      }

      if (this.moduleType == this.enumModuleType.Gallery) {
        this.dataService.createModuleGallery(newModule).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été créée.' });
            if (this.module) {
              this.module.identificationName = module.identificationName;
              this.module.id = module.id;
            }

            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.error);

            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.QuizTrueFalse) {
        this.dataService.createModuleQuizTrueFalse(module).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été créée.' });
            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.QuizMultipleChoice) {
        this.dataService.createModuleQuizMultipleChoice(module).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été créée.' });
            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.QuizIncremental) {
        this.dataService.createModuleQuizIncremental(module).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été créée.' });
            if (this.module) {
              this.module.identificationName = module.identificationName;
              this.module.id = module.id;
            }

            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else if (this.moduleType == this.enumModuleType.Custom) {
        this.dataService.createModuleCustom(newModule).subscribe({
          next: (module) => {
            this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été créé.' });
            this.navigate('module-management/' + module.id);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      }
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Champ invalide', detail: 'Le label de l\'activité est invalide.' });
    }
  }
  
  updateModule(isDuplication: boolean) {
    if (this.moduleForm.valid && this.module) {
      this.module.identificationName = this.moduleForm.get(['moduleLabel'])?.value;

      this.module.pointOfInterestId = this.moduleForm.get(['associatedPOI'])?.value;
      this.module.moduleTranslations = this.moduleTranslations;

      if (this.module.type === this.enumModuleType.Gallery) {
        this.module.mediaAssociations = this.updateMediaAssociations();
      } else if (this.module.type === this.enumModuleType.QuizTrueFalse || this.module.type === this.enumModuleType.QuizMultipleChoice) {
        this.module.questions = this.moduleService.getQuizQuestions();
      }

      if (this.moduleForm.get(['associatedPOI'])?.value != undefined && this.moduleForm.get(['associatedPOI'])?.value != 0) {
        this.module.pointOfInterestId = this.moduleForm.get(['associatedPOI'])?.value;
      } else {
        this.module.pointOfInterestId = null;
      }

      this.module.mediaSynthesisId = this.moduleService.getModule().mediaSynthesis?.id;
      this.module.mediaSynthesis = undefined;

      const module = structuredClone(this.module);

      if (this.module.type === this.enumModuleType.QuizMultipleChoice || this.module.type === this.enumModuleType.QuizTrueFalse || this.module.type === this.enumModuleType.QuizIncremental) {
        module.questions.forEach((question) => {
          question.mediaId = question.media?.id;
          question.media = undefined;
          if (this.module?.type === this.enumModuleType.QuizMultipleChoice || this.module?.type === this.enumModuleType.QuizIncremental) {
            question.answers.forEach((answer) => {
              answer.mediaId = answer.media?.id;
              answer.media = undefined;
              if (!question.answersWithImages) {
                answer.mediaId = undefined;
              }
            });
          }
        });
      }

      this.dataService.updateModule(module).subscribe({
        next: (data) => {
          if (isDuplication === true) {
            this.duplicateModule();
          } else {
            switch (data.errorCode) {
              case responseCode.SuccessfulUpdate:
                this.messageService.add({ severity: 'success', summary: 'Modification réussie', detail: data.errorMessage });
                this.module = data.data;
                this.moduleService.setModule(this.module);
                if (data.data.mediaAssociations) {
                  data.data.mediaAssociations.sort((a, b) => a.order - b.order);
                  if (this.items) {
                    this.items.clear();
                  }
                  this.setMediasAssociated(data.data.mediaAssociations);
                } else {
                  this.associatedMediaList = [];
                }
                break;
              case responseCode.NotModified:
                this.messageService.add({ severity: 'warn', summary: 'Aucune modification', detail: data.errorMessage });
                break;
              case responseCode.ImpossibleUpdate:
                this.messageService.add({ severity: 'error', summary: 'Modification impossible', detail: data.errorMessage });
                break;
            }
          }
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else {
      if (this.moduleForm.get(['moduleLabel'])?.invalid) {
        this.messageService.add({ severity: 'warn', summary: 'Modification impossible', detail: 'Le label du module être inférieur à ' + inputLength.maxModuleLabel + ' caractères.' });
      }
    }
  }

  deleteModule() {
    this.isConfirmationPopupVisible = false;

    this.dataService.deleteModule(this.moduleIdToProcess).subscribe({
      next: (result) => {
        switch (result.errorCode) {
          case responseCode.SuccessfulDeletion:
            this.moduleList = this.moduleList.filter((module) => module.id !== this.moduleIdToProcess);
            this.messageService.add({ severity: 'success', summary: result.data, detail: result.errorMessage });
            this.router.navigateByUrl(`${this.authService.getProjectName()}/module-management`);
            break;
          case responseCode.ImpossibleDeletion:
            this.messageService.add({ severity: 'error', summary: result.data, detail: result.errorMessage });
            break;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteMultipleModules() {
    const idList: number[] = [];

    this.selectedModuleList.forEach((moduleToDelete) => {
      idList.push(moduleToDelete.id);
    });
    this.dataService.deleteModules(idList).subscribe({
      next: (result) => {
        switch (result.errorCode) {
          case responseCode.SuccessfulDeletion:
            this.moduleList = this.moduleList.filter((module) => !idList.includes(module.id));
            this.selectedModuleList = [];
            this.messageService.add({ severity: 'success', summary: 'Activités supprimées', detail: 'Les activités sélectionnées ont bien été supprimées.' });
            break;
          case responseCode.ImpossibleDeletion:
            this.moduleList = this.moduleList.filter((module) => !result.data.includes(module.id));
            this.selectedModuleList = [];
            this.messageService.add({ severity: 'error', summary: 'Erreur lors de la suppression', detail: result.errorMessage });
            break;
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur lors de la suppression', detail: 'Une erreur inconnue est survenue.' });
        console.log(error.message);
      },
    });
  }

  setLanguagesForm(languageInfos: ModuleTranslation[]): number[] {
    const languageIds: number[] = [];

    languageInfos.forEach((language) => {
      languageIds.push(language.languageId);
    });

    return languageIds;
  }

  navigate(url: string) {
    const projectName = this.authService.getProjectName();
    this.router.navigateByUrl(`${projectName}/${url}`);
    this.moduleService.resetTrueFalseQuestions();
  }

  pointOfInterestAssociated(pointOfInterestId: number) {
    if (this.module) {
      this.module.order = -1;
    }

    this.isPointOfInterestAssociated = true;

    this.moduleForm.get(['associatedPOI'])?.setValue(pointOfInterestId);
    const indexPointOfInterest = this.pointOfInterestList.map((poi) => poi.id).indexOf(pointOfInterestId);
    this.associatedPointOfInterest = this.pointOfInterestList[indexPointOfInterest];

    if (this.associatedPointOfInterest.languageIds.length > 0) {
      this.associatedPointOfInterest.languageIds.forEach((languageId) => {
        if (this.moduleTranslations.map((translation) => translation.languageId).indexOf(languageId) == -1) {
          this.moduleTranslations.push(this.newModuleTranslation(languageId));
        }
      });

      this.languagesSelected = this.setLanguagesSelected();
    } else {
      this.multiLanguageForm.get(['currentLanguage'])?.setValue('');
      this.languagesSelected = [];
    }
  }

  //Multilanguage functions
  configLanguageChanged(languageId: number) {
    const indexLanguage = this.moduleTranslations.map((e) => e.languageId).indexOf(languageId);
    if (indexLanguage === -1) {
      this.moduleTranslations.push(this.newModuleTranslation(languageId));
      this.moduleTranslations.sort();
      this.languagesSelected = this.setLanguagesSelected();
    } else {
      this.languagesSelected.splice(this.languagesSelected.map((language) => language.id).indexOf(languageId), 1);
      this.moduleTranslations.splice(indexLanguage, 1);
      this.languagesSelected = this.setLanguagesSelected();
    }
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    const languageIds: number[] = [];

    this.languagesList.forEach((language) => {
      if (this.moduleTranslations.map((e) => e.languageId).indexOf(language.id) != -1) {
        languageArray.push(language);
        languageIds.push(language.id);
      }
    });

    this.moduleForm.get(['languages'])?.setValue(languageIds);

    if (this.moduleTranslations.length > 0) {
      this.selectedLanguageId = this.moduleTranslations[0].languageId;
      this.multiLanguageForm.get(['currentLanguage'])?.setValue(this.selectedLanguageId);
      this.multiLanguageForm.get(['moduleName'])?.setValue(this.moduleTranslations[0].name);
      this.textSynthesis.setValue(this.moduleTranslations[0].synthesis);
    }

    return languageArray;
  }

  newModuleTranslation(languageId: number): ModuleTranslation {
    return {
      translatedEntityId: this.module!.id,
      languageId: languageId,
      name: '',
      synthesis: ''
    };
  }

  saveTranslation() {
    const indexLanguageModifications = this.moduleTranslations.map((moduleTranslation) => moduleTranslation.languageId).indexOf(this.selectedLanguageId);

    if (indexLanguageModifications != -1) {
      const newModuleTranslation: ModuleTranslation = {
        translatedEntityId: this.module!.id,
        languageId: this.selectedLanguageId,
        name: this.multiLanguageForm.get(['moduleName'])?.value,
        synthesis: this.textSynthesis.value
      };
      this.moduleTranslations[indexLanguageModifications] = newModuleTranslation;
    }
  }

  languageChanged(currentLanguageId: number) {
    this.saveTranslation();

    const indexCurrentLanguage = this.moduleTranslations.map((e) => e.languageId).indexOf(currentLanguageId);

    this.multiLanguageForm.get(['moduleName'])?.setValue(this.moduleTranslations[indexCurrentLanguage].name);
    this.textSynthesis.setValue(this.moduleTranslations[indexCurrentLanguage].synthesis);

    this.selectedLanguageId = currentLanguageId;
  }

  //Confirmation popup functions
  showConfirmationPopup(moduleId: number, multipleDeletion: boolean) {
    if (multipleDeletion == true && this.selectedModuleList.length == 0) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Aucune activité séletionnée', detail: 'Sélectionnez une ou plusieurs activités pour effectuer cette action.' });
    } else {
      this.moduleIdToProcess = moduleId;
      this.multipleDeletion = multipleDeletion;
      this.confirmTypeAction = this.enumDialogType.Delete;
      this.entityTypeAction = this.enumEntityType.Module;
      this.isConfirmationPopupVisible = true;
    }
  }

  confirmActionDialog(confirmAction: boolean) {
    this.isConfirmationPopupVisible = false;
    if (confirmAction == true) {
      if (this.multipleDeletion == false) {
        this.deleteModule();
      } else {
        this.deleteMultipleModules();
      }
    }
  }

  removePointOfInterestAssociation() {
    this.moduleForm.get(['associatedPOI'])?.setValue(undefined);
  }

  //Gallery module functions
  setMediasAssociated(medias: MediaAssociation[]) {
    medias.forEach((mediaAssociation, index) => {
      this.addFormItem();
      this.items?.at(index).get(['mediaName'])?.setValue(mediaAssociation.media.name);
      this.items?.at(index).get(['is360'])?.setValue(mediaAssociation.is360);
      this.items?.at(index).get(['order'])?.setValue(mediaAssociation.order);
    });
    this.associatedMediaList = medias;
  }

  createFormItem(): FormGroup {
    return this.fb.group({
      mediaName: [{ value: '', disabled: true }, Validators.required],
      is360: [false],
      order: [''],
    });
  }

  addFormItem(): void {
    this.items = this.mediaForm.get('items') as FormArray;
    this.items.push(this.createFormItem());
  }

  addMediaLine() {
    this.associatedMediaList.push(this.newMediaAssociation());
    this.addFormItem();
  }

  newMediaAssociation(): MediaAssociation {
    return {
      media: {
        id: -1,
        name: '',
        extension: '',
        createdAt: new Date(),
        fileType: fileType.Image,
        mimeFileType: '',
      },
      is360: false,
      order: -1,
    };
  }

  removeMedia(index: number) {
    const indexRemoveFormItem = this.items
      ?.getRawValue()
      .map((i) => i.order)
      .indexOf(index + 1);
    for (let i = 0; i < this.associatedMediaList.length; i++) {
      const orderFromform = this.items?.at(i).get(['order'])?.getRawValue();

      if (orderFromform > index) {
        this.items
          ?.at(i)
          .get(['order'])
          ?.setValue(orderFromform - 1);
      }

      if (i > index) {
        this.associatedMediaList[i - 1] = this.associatedMediaList[i];
        this.associatedMediaList[i].order -= 1;
      }
    }

    if (indexRemoveFormItem !== undefined) {
      this.items?.removeAt(indexRemoveFormItem);
    }

    this.associatedMediaList.splice(this.associatedMediaList.length - 1, 1);
  }

  associateMedia(mediaAssociation: MediaAssociation) {
    this.addFormItem();

    this.associatedMediaList[mediaAssociation.order - 1] = mediaAssociation;

    this.items
      ?.at(mediaAssociation.order - 1)
      .get(['mediaName'])
      ?.setValue(mediaAssociation.media.name);
    this.items
      ?.at(mediaAssociation.order - 1)
      .get(['is360'])
      ?.setValue(mediaAssociation.is360);
    this.items
      ?.at(mediaAssociation.order - 1)
      .get(['order'])
      ?.setValue(mediaAssociation.order);
  }

  updateMediaAssociations(): MediaAssociation[] {
    const mediaAssociationList: MediaAssociation[] = [];

    const formArray = this.items?.getRawValue();

    this.associatedMediaList.forEach((mediaAssociation) => {
      if (formArray != undefined) {
        const indexForArray = formArray.map((p) => p.order).indexOf(mediaAssociation.order);
        mediaAssociation.is360 = this.items?.at(indexForArray).get(['is360'])?.value;
        mediaAssociationList.push(mediaAssociation);
      }
    });

    return mediaAssociationList;
  }
}
