<div tabIndex="0" aria-hidden="true" class="itinerary-card">
  <div>
    <h3 [title]="'Label du parcours : ' + itinerary.identificationName" class="truncate-one-line">
      <a routerLink="/{{ this.getProjectName() }}/itinerary-management/{{ this.itinerary.id }}">{{ itinerary.identificationName }}</a>
    </h3>
    <div class="horizontal-display">
      <span
        class="itinerary-state"
        title="État de publication du parcours"
        [ngClass]="itinerary.state === enumPublishState.Published ? 'green-state' : itinerary.state === enumPublishState.Modified ? 'orange-state' : 'red-state'">
        {{ displayPointOfInterestState() | titlecase }}
      </span>
      <app-completion-state class="margin-left" [completionState]="itinerary.readyForPublication" [tooltipTrue]="'Le parcours est complet'" [tooltipFalse]="'Le parcours n\'est pas complet'">
      </app-completion-state>
    </div>
  </div>

  <div class="right-section">
    <div class="infos-itinerary">
      <div class="infos-itinerary-item" title="Nombre d'étapes associées au parcours.">
        <p>{{ itinerary.countOfPointOfInterest }}</p>
        <i class="bi bi-geo infos-itinerary-icon"></i>
      </div>
      <div class="infos-itinerary-item" title="Nombre d'activités associées au parcours.">
        <p>{{ itinerary.countOfModules }}</p>
        <i class="bi bi-puzzle infos-itinerary-icon"></i>
      </div>
    </div>

    <div class="action-buttons">
      <a routerLink="/{{ this.getProjectName() }}/itinerary-management/{{ this.itinerary.id }}" title="Modifier" class="action-buttons-item">
        <i class="bi bi-pencil"></i>
      </a>
      <!-- TODO: remove aria-hidden -->
      <i
        tabIndex="0"
        aria-hidden="true"
        (click)="$event.stopPropagation(); showDialog(enumDialogType.Delete, enumEntityType.Itinerary)"
        (keypress)="$event.stopPropagation(); showDialog(enumDialogType.Delete, enumEntityType.Itinerary)"
        class="bi bi-trash action-buttons-item"
        title="Supprimer">
      </i>
    </div>
  </div>
</div>

<app-confirmation-popup [visible]="visible" [confirmTypeAction]="confirmTypeAction" [entityTypeAction]="entityTypeAction" (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
