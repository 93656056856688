<div class="body">
  <div class="page">
    <div class="header">
      <h1>Bibliothèque</h1>
      <button class="button" (click)="visibleMediaManagementPopup()" title="Ajouter un nouveau média">Ajouter</button>
    </div>

    <div class="filter-section">
      <!-- TODO: Create a iltering section -->
    </div>

    <p-table
      #mediaTable
      [value]="mediaList"
      [rows]="10"
      selectionMode="multiple"
      [(selection)]="selectedMediaList"
      dataKey="id"
      [(first)]="first"
      [selectionPageOnly]="true"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} à {last}, {totalRecords} médias"
      [rowsPerPageOptions]="[10, 20, 50]">

      <!-- TODO: add this line to enable filtering
      [globalFilterFields]="['name', 'createdAt', 'representative.name', 'status']" -->
      <!-- <ng-template pTemplate="caption">
        <div class="flex">
          <input 
              pInputText
              type="text" 
              (input)="mediaTable.filterGlobal($event.target, 'contains')" 
              placeholder="Search keyword" />
        </div>
      </ng-template> -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox title="Sélectionner tous les médias de la page"></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="name">Nom <p-sortIcon field="name"></p-sortIcon></th>
          <th pSortableColumn="createdAt">Crée le <p-sortIcon field="createdAt"></p-sortIcon></th>
          <th style="width: 4rem">Type</th>
          <th style="width: 4rem">Ext</th>
          <th pSortableColumn="size" style="width: 7rem">Taille <p-sortIcon field="size"></p-sortIcon></th>
          <th style="width: 4rem">
            <i
              tabIndex="0"
              aria-hidden="true"
              class="bi bi-trash btn-remove-file"
              title="Supprimer les médias sélectionnés"
              (click)="showConfirmationPopup(-1, true); $event.stopPropagation()"
              (keypress)="showConfirmationPopup(-1, true); $event.stopPropagation()">
            </i>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-media>
        <tr (mousedown)="clickOnArray($event)" (click)="displayMedia($event, media)" class="table">
          <td>
            <p-tableCheckbox title="Sélectionner ce média" [value]="media" (click)="$event.stopPropagation()"></p-tableCheckbox>
          </td>
          <td>{{ media.name }}</td>
          <td>{{ media.createdAt | date: "short" }}</td>
          <td>{{ displayFileType(media.fileType) }}</td>
          <td>{{ media.extension | lowercase }}</td>
          <td>{{ displayFileSize(media.size) }}</td>
          <td>
            <i
              tabIndex="0"
              aria-hidden="true"
              class="bi bi-trash btn-remove-file"
              title="Supprimer ce média"
              (click)="showConfirmationPopup(media.id, false); $event.stopPropagation()"
              (keypress)="showConfirmationPopup(media.id, false); $event.stopPropagation()">
            </i>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">Aucun média n'est enregistré dans l'application.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-media-management-popup
  [visible]="mediaManagementPopupVisible"
  [mediaToDisplay]="mediaToDisplay"
  [fileUrl]="fileUrl"
  [fileBlob]="fileBlob"
  (closePopupEmitter)="closePopup()"
  (uploadMediaEmitter)="addToMediaList($event)"
  (remaneMediaEmitter)="renameMedia($event)">
</app-media-management-popup>

<app-confirmation-popup
  [visible]="confirmationPopupVisible"
  [confirmTypeAction]="confirmTypeAction"
  [entityTypeAction]="entityTypeAction"
  [multipleSelection]="multipleDeletion"
  [entityId]="mediaIdToProcess"
  (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
