export class ItineraryPublication {
  id!: number;
  identificationName!: string;
  state!: number;
  readyForPublication!: boolean;
  pointOfInterests: PointOfInterestPublication[] = [];
}

export class PointOfInterestPublication {
  id!: number;
  identificationName!: string;
  readyForPublication!: boolean;
  modules: ModulePublication[] = [];
}

export class ModulePublication {
  id!: number;
  identificationName!: string;
  readyForPublication!: boolean;
}
