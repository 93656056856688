import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { displayValue, fileType } from 'app/Enumerations/file-type.enum';
import { Media } from 'app/Models/media';
import { MediaAssociation } from 'app/Models/module';
import { DataService } from 'app/Services/Data-Service/data.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-media-association',
  templateUrl: './media-association.component.html',
  styleUrl: './media-association.component.css',
})
export class MediaAssociationComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() mediaList: Media[] = [];
  @Input() mediaVisualisationFromPage: boolean = false;
  @Input() mediaToDisplay?: MediaAssociation;

  @Input() fileBlob?: Blob;
  @Input() fileUrl?: string;

  mediaVisualisation: boolean = false;

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() associatedMediaEmitter: EventEmitter<Media> = new EventEmitter<Media>();

  @ViewChild('video') videoReader!: ElementRef;
  @ViewChild('image') imageReader!: ElementRef;

  selectedMediaList: Media[] = [];
  selectedMedia?: Media;

  mediaIsLoading: boolean = true;

  dimensionsMedia: number[] = [];

  //Forms
  checkBoxForm: FormGroup;

  //Enumerations
  enumFileType = fileType;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private messageService: MessageService,
  ) {
    this.checkBoxForm = this.fb.group({
      isChecked: [false],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fileBlob']) {
      if (changes['fileBlob'].currentValue !== null && changes['fileBlob'].currentValue !== undefined) {
        this.mediaIsLoading = false;
      }
      if (this.fileBlob && this.mediaToDisplay?.media.fileType == this.enumFileType.Video) {
        this.videoReader.nativeElement.src = this.fileUrl;
      }
    }
  }

  visualizeMedia(media: Media) {
    this.mediaVisualisation = true;
    this.mediaIsLoading = true;
    this.mediaToDisplay = new MediaAssociation(0, media, false, 0);
    
    if (media.fileType === fileType.Image || media.fileType === fileType.Pdf){
      this.dataService.getFile(media).subscribe({
        next: (file) => {
          this.fileBlob = file;
          this.fileUrl = URL.createObjectURL(file);
          this.mediaIsLoading = false;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else {
      this.fileUrl = this.dataService.streamFile(media);
    }
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  displayDimensionsImage(): void {
    this.dimensionsMedia[0] = this.imageReader.nativeElement.naturalWidth;
    this.dimensionsMedia[1] = this.imageReader.nativeElement.naturalHeight;
  }

  displayDimensionsVideo(): void {
    this.dimensionsMedia[0] = this.videoReader.nativeElement.videoWidth;
    this.dimensionsMedia[1] = this.videoReader.nativeElement.videoHeight;
  }

  downloadPdf() {
    const link = document.createElement('a');
    if (this.fileUrl && this.mediaToDisplay) {
      link.href = this.fileUrl;
      link.download = this.mediaToDisplay?.media.name + this.mediaToDisplay?.media.extension;
      link.click();
      link.remove();
    }
  }

  returnToList() {
    this.mediaVisualisation = false;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
    this.mediaIsLoading = false;
  }

  associateMedia() {
    if (this.selectedMedia) {
      this.associatedMediaEmitter.emit(this.selectedMedia);
      this.initializePopup();
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Aucun média séletionné', detail: 'Sélectionnez un média pour effectuer cette action.' });
    }
  }

  closePopup() {
    this.closePopupEmitter.emit();
    this.initializePopup();
  }

  initializePopup() {
    this.mediaVisualisation = false;
    this.mediaToDisplay = undefined;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
    this.selectedMedia = undefined;
    this.mediaIsLoading = true;
    this.dimensionsMedia = [];
    
  }
}
