import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { displayValue, fileType } from 'app/Enumerations/file-type.enum';
import { Media } from 'app/Models/media';
import { Module } from 'app/Models/module';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';
import { UploadFilesService } from 'app/Services/Upload-Service/upload-files.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-media-management-popup',
  templateUrl: './media-management-popup.component.html',
  styleUrls: ['./media-management-popup.component.css'],
})
export class MediaManagementPopupComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false;
  @Input() mediaToDisplay?: Media;
  @Input() fileBlob?: Blob;
  @Input() fileUrl?: string;

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadMediaEmitter: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() remaneMediaEmitter: EventEmitter<Media> = new EventEmitter<Media>();

  @ViewChild('fileUploader') fileUploader!: ElementRef;
  @ViewChild('video') videoReader!: ElementRef;
  @ViewChild('image') imageReader!: ElementRef;
  @ViewChildren('progressBar') progressBars!: QueryList<ElementRef>;

  mediaForm: FormGroup;

  uploadedFiles: File[] = [];
  allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'audio/mpeg', 'audio/aiff', 'audio/wav', 'video/quicktime', 'video/mp4'];

  fileString = '';

  indexFileToRename: number = -1;

  uploadedFilesCount: number = 0;
  progressArray!: number[];
  uploadStarted: boolean = false;

  isRenameFileAfterUpload = false;

  fileSizeMax: number = 524_288_000;

  modulesAssociatedToMedia: Module[] = [];

  mediaIsLoading: boolean = true;
  modulesAssociatedAreLoading: boolean = true;

  dimensionsMedia: number[] = [];

  // Upload files outside popup
  uploadCardFolded = false;

  //Enumerations
  enumFileType = fileType;

  inputLength = inputLength;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private messageService: MessageService,
    private authService: AuthService,
    private uploadFilesService: UploadFilesService
  ) {
    this.mediaForm = this.fb.group({
      mediaLabel: ['', [Validators.required, Validators.maxLength(inputLength.maxMediaLabel)]],
      mediaType: [''],
      mediaSize: [''],
    });
  }
  
  ngOnInit() {
    this.uploadFilesService.uploadProgressArray$.subscribe((uploadProgressArray) => {
      this.progressArray = uploadProgressArray;
    });

    this.uploadFilesService.filesToUpload$.subscribe((filesToUpload) => {
      this.uploadedFiles = filesToUpload;
    });

    this.uploadFilesService.uploadInProgress$.subscribe((uploadInProgress) => {
      this.uploadStarted = uploadInProgress;
      if(!uploadInProgress) {
        this.closePopup();
      }
    });
  }

  displayUploadedFilesCount() {
    this.uploadedFilesCount = this.progressArray.reduce((acc, curr) => {
      return curr === 100 ? acc + 1 : acc;
    }, 0);
    return this.uploadedFilesCount;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['fileBlob']) {
      
      if (changes['fileBlob'].currentValue !== null && changes['fileBlob'].currentValue !== undefined) {
        this.mediaIsLoading = false;
      }
      
      if (this.fileBlob && this.mediaToDisplay?.fileType == this.enumFileType.Video) {
        this.videoReader.nativeElement.src = this.fileUrl;
      }
    } else if (changes['visible'].currentValue == true && this.mediaToDisplay) {
      this.dataService.getEntitiesAssociatedToMedia(this.mediaToDisplay.id).subscribe({
        next: (modules) => {
          this.modulesAssociatedToMedia = modules;
          this.modulesAssociatedAreLoading = false;
        },
        error: (error) => {
          console.log(error.message);
        }
      });
    }
    
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  addFiles() {
    if (this.uploadStarted) {
      this.messageService.add({ severity: 'warn', summary: 'Format non accepté', detail: "Vous ne pouvez pas ajouter de nouveaux fichier lorsqu'un upload est en cours." });
    } else {
      const files: FileList = this.fileUploader.nativeElement.files;

      let invalidFiles = false;

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          if (this.allowedFileTypes.indexOf(files[i].type) != -1 && files[i].size <= this.fileSizeMax) {
            const fileName = files[i].name.substring(0, files[i].name.lastIndexOf('.'));
            if (fileName.length > 100) {
            const newFileName = fileName.substring(0, 100) + files[i].name.substring(files[i].name.lastIndexOf('.'));
            const newFile = new File([files[i]], newFileName, { type: files[i].type });
            this.uploadedFiles.push(newFile);
          } else {
            this.uploadedFiles.push(files[i]);
          }
          } else {
            invalidFiles = true;
          }
        }
        this.uploadFilesService.setfiles(this.uploadedFiles);
      }

      this.fileUploader.nativeElement.value = '';

      if (invalidFiles === true) {
        this.messageService.add({ severity: 'error', summary: 'Fichier(s) non valide(s)', detail: "Un ou plusieurs fichiers sont dans un format non accepté par l'application ou sont trop volumineux." });
      }
    }
  }

  displayFileSize(size: number | undefined): string {
    if (size == undefined) {
      return '';
    }
    if (size < 1000) {
      return '' + size + ' o';
    } else if (size < 1_000_000) {
      return '' + (size / 1024).toFixed(1) + ' Ko';
    } else if (size < 1_000_000_000) {
      return '' + (size / 1_048_576).toFixed(1) + ' Mo';
    } else {
      return '' + (size / 1_073_741_824).toFixed(1) + ' Go';
    }
  }

  downloadPdf() {
    const link = document.createElement('a');
    if (this.fileUrl && this.mediaToDisplay) {
      link.href = this.fileUrl;
      link.download = this.mediaToDisplay?.name + this.mediaToDisplay?.extension;
      link.click();
      link.remove();
    }
  }

  clickFileUploader() {
    this.fileUploader.nativeElement.click();
  }

  fileDropHandler(event: DragEvent) {
    event.preventDefault();

    if (this.uploadStarted) {
      this.messageService.add({ severity: 'warn', summary: 'Ajout impossible', detail: "Vous ne pouvez pas ajouter de nouveaux fichier lorsqu'un upload est en cours." });
    } else {
      let invalidFiles: boolean = false;
  
      if (event.dataTransfer && event.dataTransfer.items) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          if (event.dataTransfer.items[i].kind === 'file') {
            const file = event.dataTransfer.items[i].getAsFile();
            if (file != undefined && this.allowedFileTypes.indexOf(file.type) != -1 && file.size <= this.fileSizeMax) {
              this.uploadedFiles.push(file);
            } else {
              invalidFiles = true;
            }
          }
        }
      } else if (event.dataTransfer && event.dataTransfer.files) {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          if (this.allowedFileTypes.indexOf(event.dataTransfer.files[i].type) != -1 && event.dataTransfer.files[i].size <= this.fileSizeMax) {
            this.uploadedFiles.push(event.dataTransfer.files[i]);
          } else {
            invalidFiles = true;
          }
        }
      }
      if (invalidFiles == false) {
        // TODO: understand why there is an error when I write invalidFiles = true
      } else {
        this.messageService.add({ severity: 'error', summary: 'Format non accepté', detail: "Un ou plusieurs fichiers sont dans un format non accepté par l'application." });
      }
      this.uploadFilesService.setfiles(this.uploadedFiles);
    }
  }

  displayMimeFileType(fileType: string) {
    if (fileType == 'application/pdf') {
      return 'PDF';
    } else {
      return fileType.substring(0, fileType.indexOf('/'));
    }
  }

  dragFileOverHandler(event: DragEvent) {
    event.preventDefault();
  }

  displayExtention(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.'));
  }

  renameFileAfterUpload() {
    this.isRenameFileAfterUpload = true;
    this.mediaForm.get(['mediaLabel'])?.setValue(this.mediaToDisplay?.name);
  }

  renameFile(index: number) {
    this.indexFileToRename = index;

    const fileName = this.uploadedFiles[index].name.substring(0, this.uploadedFiles[index].name.lastIndexOf('.'));

    this.mediaForm.get(['mediaLabel'])?.setValue(fileName);
  }

  confirmRenameFile(index: number) {
    if (this.mediaForm.get(['mediaLabel'])?.valid) {
      const fileReader = new FileReader();

      fileReader.readAsBinaryString(this.uploadedFiles[index]);

      const extension = this.uploadedFiles[index].name.substring(this.uploadedFiles[index].name.lastIndexOf('.'));

      const fileName = this.mediaForm.get(['mediaLabel'])?.value + extension;

      const newFile = new File([this.uploadedFiles[index]], fileName, { type: this.uploadedFiles[index].type });

      this.uploadedFiles[index] = newFile;

      this.indexFileToRename = -1;
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Nom invalide', detail: 'Le nom du fichier doit être de 100 caractères maximum.' });
    }
  }

  confirmRenameFileAfterUpload() {
    if (this.mediaForm.get(['mediaLabel'])?.valid && this.mediaToDisplay) {
      const newMedia = {
        id: this.mediaToDisplay?.id,
        name: this.mediaForm.get(['mediaLabel'])?.value,
        extension: this.mediaToDisplay?.extension,
        createdAt: this.mediaToDisplay?.createdAt,
        fileType: this.mediaToDisplay?.fileType,
        mimeFileType: this.mediaToDisplay?.mimeFileType,
      };
      this.isRenameFileAfterUpload = false;
      this.dataService.updateMediaName(newMedia).subscribe({
        next: (media) => {
          if (this.mediaToDisplay) {
            this.mediaToDisplay.name = this.mediaForm.get(['mediaLabel'])?.value;
          }
          this.remaneMediaEmitter.emit(media);
        },
        error: (error) => {
          console.log(error.message);
        },
      });

    }
  }

  displayDimensionsImage(): void {
    this.dimensionsMedia[0] = this.imageReader.nativeElement.naturalWidth;
    this.dimensionsMedia[1] = this.imageReader.nativeElement.naturalHeight;
  }

  displayDimensionsVideo(): void {
    this.dimensionsMedia[0] = this.videoReader.nativeElement.videoWidth;
    this.dimensionsMedia[1] = this.videoReader.nativeElement.videoHeight;
  }

  cancelRenameFile() {
    this.indexFileToRename = -1;
    this.isRenameFileAfterUpload = false;
  }

  removeFile(file: File) {
    const fileIndex = this.uploadedFiles.indexOf(file)
    this.uploadedFiles.splice(fileIndex, 1);
    if (this.indexFileToRename > fileIndex) {
      this.indexFileToRename--;
    } else if (this.indexFileToRename === fileIndex) {
      this.indexFileToRename = -1;
    }
  }

  upload() {
    if (this.indexFileToRename != -1) {
      this.messageService.add({ severity: 'warn', summary: 'Upload impossible', detail: 'Un fichier est en cours de renommage, veuillez terminer cette action avant d\'uploader les fichiers.' });
    } else if (this.uploadedFiles[0]) {
      this.uploadFilesService.uploadFiles();
      this.uploadStarted = true;
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Aucun fichier ajouté', detail: 'Veuillez choisir des fichiers pour procéder à leur ajout.' });
    }

  }

  closePopup() {
    this.closePopupEmitter.emit();
    if (!this.uploadStarted) {
      this.uploadedFiles = [];
      this.progressArray = [];
      this.uploadedFilesCount = 0;
    }
    this.uploadFilesService.setVisiblePopup(false);
    this.modulesAssociatedToMedia = [];
    this.fileUrl = undefined;
    this.fileBlob = undefined;
    this.indexFileToRename = -1;
    this.isRenameFileAfterUpload = false;
    this.mediaIsLoading = true;
    this.modulesAssociatedAreLoading = true;
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  countMediaLabelCharacters() {
    return this.mediaForm.get('mediaLabel')?.value.length;
  }
}
