import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';

@Component({
  selector: 'app-point-of-interest-configuration',
  templateUrl: './point-of-interest-configuration.component.html',
  styleUrls: ['./point-of-interest-configuration.component.css'],
})
export class PointOfInterestConfigurationComponent {
  @Input() configurationForm: FormGroup;

  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  // decimalRegex = /\d+(?:[\.,]\d+)?/;

  inputLength = inputLength;

  constructor(private fb: FormBuilder) {
    this.configurationForm = this.fb.group({
      pointOfInterestLabel: ['', Validators.required],
      languages: [''],
      latitude: ['', [Validators.min(-90), Validators.max(90)]],
      longitude: ['', [Validators.min(-180), Validators.max(180)]],
      isLocated: [false],
    });
  }

  isLocated(): boolean {
    if (this.configurationForm.get(['isLocated'])?.value == true) return true;
    else return false;
  }

  countLabelCharacters(): string {
    if (this.configurationForm.get(['pointOfInterestLabel'])?.value) {
      return this.configurationForm.get(['pointOfInterestLabel'])?.value.length;
    } else {
      return '0';
    }
  }
}
