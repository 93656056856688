import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { fileType } from 'app/Enumerations/file-type.enum';
import { Media } from 'app/Models/media';
import { MediaAssociation } from 'app/Models/module';
import { DataService } from 'app/Services/Data-Service/data.service';

@Component({
  selector: 'app-module-gallery',
  templateUrl: './module-gallery.component.html',
  styleUrl: './module-gallery.component.css',
})
export class ModuleGalleryComponent {
  @Input() mediaList: Media[] = [];
  @Input() associatedMediaList: MediaAssociation[] = [];

  @Output() associatedMediaEmitter: EventEmitter<MediaAssociation> = new EventEmitter<MediaAssociation>();
  @Output() deleteAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();

  indexPopupOpened: number = -1;

  mediaVisualisationFromPage: boolean = false;

  fileBlob?: Blob;
  fileUrl?: string;

  isPopupVisible: boolean = false;

  //Forms
  @Input() mediaForm: FormGroup;
  @Input() items: FormArray | undefined;

  distanceBetweenCards: number = 50;
  cardHeight!: number;

  @ViewChildren('dragItem', { read: ElementRef }) dragItems!: QueryList<ElementRef>;

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
  ) {
    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  addMediaLine() {
    this.associatedMediaList.push(this.newMediaAssociation());
  }

  newMediaAssociation(): MediaAssociation {
    return {
      media: {
        id: -1,
        name: '',
        extension: '',
        createdAt: new Date(),
        fileType: fileType.Image,
        mimeFileType: '',
      },
      is360: false,
      order: -1,
    };
  }

  removeMedia(index: number) {
    this.deleteAssociatedMediaEmitter.emit(index);
  }

  showPopupV2() {
    this.indexPopupOpened = this.associatedMediaList.length;
    this.isPopupVisible = true;
    this.mediaVisualisationFromPage = false;
  }

  showPopup(index: number, mediaVisualisationFromPage: boolean) {
    this.indexPopupOpened = index;
    this.isPopupVisible = true;
    if (mediaVisualisationFromPage === true) {
      this.mediaVisualisationFromPage = mediaVisualisationFromPage;

      if (this.associatedMediaList[index].media.fileType === fileType.Image || this.associatedMediaList[index].media.fileType === fileType.Pdf) {
        this.dataService.getFile(this.associatedMediaList[index].media).subscribe({
          next: (file) => {
            this.fileBlob = file;
            this.fileUrl = URL.createObjectURL(file);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else {
        this.fileUrl = this.dataService.streamFile(this.associatedMediaList[index].media);
      }
    }
  }

  associateMedia(media: Media) {
    const newMediaAssociation = {
      media: media,
      is360: false,
      order: this.indexPopupOpened + 1,
    };

    this.closePopup();
    this.associatedMediaEmitter.emit(newMediaAssociation);
  }

  closePopup() {
    this.isPopupVisible = false;
    this.mediaVisualisationFromPage = false;
    this.indexPopupOpened = -1;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
    
  }

  getHeightCard(): number {
    if (this.associatedMediaList && this.associatedMediaList.length > 0) {
      return this.associatedMediaList.length * this.distanceBetweenCards + 100;
    } else {
      // return 180;
      return 150;
    }
  }
}
