import { moduleType } from 'app/Enumerations/module-type.enum';
import { Media } from './media';
import { Project } from './project';

export class Module {
  id!: number;
  pointOfInterestId!: number | null;
  identificationName!: string;
  state!: number;
  order!: number;
  type!: moduleType;
  readyForPublication!: boolean;
  mediaCount?: number;
  createdAt?: Date;
  publicationDate?: Date;
  lastModificationDate?: Date;
  mediaSynthesis?: Media;
  mediaSynthesisId?: number;
  moduleTranslations: ModuleTranslation[] = [];
  // ModuleQuiz - move to another class later
  questions: Question[] = [];
  // ModuleGallery - move to another class later
  mediaAssociations: MediaAssociation[] = [];
  // ModuleCustom - move to another class later
  moduleCustomTemplateId? : number;
}

// export class ModuleGallery extends Module {
//   mediaAssociations: MediaAssociation[] = [];
// }

export class ModuleTranslation {
  translatedEntityId!: number;
  languageId!: number;
  name!: string;
  synthesis!: string;
}

export class MediaAssociation {
  id?: number;
  media!: Media;
  is360!: boolean;
  order!: number;

  constructor(newId: number, media: Media, is360: boolean, order: number) {
    this.id = newId,
    this.media = media,
    this.is360 = is360,
    this.order = order
  }
}

export class Question {
  questionTranslations: QuestionTranslation[] = [];
  media?: Media;
  mediaId?: number;
  // For the true/false module, the possibilities are : 0 == undefined, 2 == false and 1 == true for the correct answer
  correctAnswer?: number;
  answersWithImages?: boolean;
  answers: Answer[] = [];

  constructor(questionTranslations: QuestionTranslation[],
    media: Media, 
    correctAnswer: number, 
    answersWithImages: boolean, 
    answers: Answer[]) {
    
    this.questionTranslations = questionTranslations,
    this.mediaId = media.id,
    this.correctAnswer = correctAnswer,
    this.answersWithImages = answersWithImages,
    this.answers = answers
  }
}

export class QuestionTranslation {
  translatedEntityId!: number;
  languageId!: number;
  question!: string;
  answerExplanation!: string;
}

export class Answer {
  answerTranslations: AnswerTranslation[] = [];
  media?: Media;
  mediaId?: number;
}

export class AnswerTranslation {
  translatedEntityId!: number;
  languageId!: number;
  answer!: string;
  answerExplanation!: string;

  constructor(translatedEntityId: number, languageId: number, answer: string, answerExplanation: string) {
    this.translatedEntityId = translatedEntityId;
    this.languageId = languageId;
    this.answer = answer;
    this.answerExplanation = answerExplanation;
  }
}

export class ModuleCustomTemplate {
  id! : number;
  moduleKey! : string;
  name! : string;
  projects: Project[] = [];

  constructor(id: number, moduleKey: string | undefined, name: string | undefined) {
    this.id = id;
    this.moduleKey = moduleKey || '';
    this.name = name || '';
  }
}

export class ModuleCustomAssociation {
  moduleId! : number;
  projectId! : number;
  isAssociated! : boolean;

  constructor(moduleId: number, projectId: number, isAssociated: boolean) {
    this.moduleId = moduleId;
    this.projectId = projectId;
    this.isAssociated = isAssociated;
  }
}