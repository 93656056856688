import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { correctAnswerEnum } from 'app/Enumerations/correct-answer.enum';
import { fileType } from 'app/Enumerations/file-type.enum';
import { moduleType } from 'app/Enumerations/module-type.enum';
import { Language } from 'app/Models/language';
import { Media } from 'app/Models/media';
import { ModuleTranslation, QuestionTranslation, Question, Answer, AnswerTranslation, MediaAssociation } from 'app/Models/module';
import { DataService } from 'app/Services/Data-Service/data.service';
import { ModuleService } from 'app/Services/Module-Service/module.service';
import { MessageService } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'app-module-multilanguage-content',
  templateUrl: './module-multilanguage-content.component.html',
  styleUrl: './module-multilanguage-content.component.css',
})
export class ModuleMultilanguageContentComponent implements OnInit, OnChanges {
  @Input() isPointOfInterestAssociated: boolean = false;
  @Input() languagesSelected: Language[] = [];
  @Input() moduleTranslations: ModuleTranslation[] = [];
  @Input() moduleType!: number;
  @Input() mediaList: Media[] = [];

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveTranslationEmitter: EventEmitter<void> = new EventEmitter<void>();

  quizQuestions: Question[] = [];
  showQuestion: boolean[] = [];

  imageSynthesis?: Media;

  // Forms
  @Input() multiLanguageForm!: FormGroup;
  @Input() trueFalseQuizForm!: FormGroup;
  @Input() items: FormArray | undefined;
  @Input() textSynthesis: FormControl = new FormControl('', Validators.maxLength(inputLength.maxQuizSynthesis));

  // Popup
  visibleMediaPopup: boolean = false;
  indexQuestionImage: number = -1;
  indexAnswerImage: number = -1;
  mediaVisualisationFromPage = false;
  selectedMedia!: MediaAssociation;
  fileBlob?: Blob;
  fileUrl?: string;

  // Enumerations
  enumModuleType = moduleType;
  enumCorrectAnswer = correctAnswerEnum;

  inputLength = inputLength;

  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private messageService: MessageService,
    private dataService: DataService
  ) {
    this.multiLanguageForm = this.fb.group({
      moduleName: [''],
      currentLanguage: [''],
    });

    this.trueFalseQuizForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  ngOnInit() {
    if (this.moduleType === moduleType.QuizIncremental && this.moduleService.getQuizQuestions().length === 0) {
      this.addQuestion();
    }

    this.moduleService.listOfTrueFalseQuestions.subscribe((list) => {
      this.quizQuestions = list;

      list.forEach((question) => {
        if (this.items === undefined || this.items!.length < list.length) {
          this.addFormItemWithQuestion(question);
        }
      });
    });

    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mediaList']) {
      this.mediaList = this.mediaList.filter(m => m.fileType === fileType.Image);
    }

    if (changes['moduleType']) {
      this.moduleType = changes['moduleType'].currentValue;
      if (this.moduleService.getModule().mediaSynthesis !== undefined && this.moduleService.getModule().mediaSynthesis !== null) {
        this.imageSynthesis = this.moduleService.getModule().mediaSynthesis;      
      }
    }

    if (changes['languagesSelected'] && this.multiLanguageForm.get(['currentLanguage'])?.value) {
      this.setLanguage(this.multiLanguageForm.get(['currentLanguage'])?.value);
    }
  }

  setLanguage(languageSelected: number) {
    //for module name only
    this.languageChangedEmitter.emit(languageSelected);

    //for questions
    if (this.moduleType === moduleType.QuizTrueFalse || this.moduleType === moduleType.QuizMultipleChoice || this.moduleType === moduleType.QuizIncremental) {
      const currentLanguage = this.multiLanguageForm.get(['currentLanguage'])?.value;
      
      this.quizQuestions.forEach((question, indexQuestion) => {
        const indexLanguage = question.questionTranslations.map((e) => e.languageId).indexOf(currentLanguage);

        this.items?.at(indexQuestion).get('questionInput')?.setValue(question.questionTranslations[indexLanguage].question);
        if (this.moduleType === moduleType.QuizTrueFalse || this.moduleType === moduleType.QuizMultipleChoice) {
          this.items?.at(indexQuestion).get('answerExplanation')?.setValue(question.questionTranslations[indexLanguage].answerExplanation);
        } 
        if (this.moduleType === moduleType.QuizMultipleChoice || this.moduleType === moduleType.QuizIncremental) {
          const answersArray = this.items?.at(indexQuestion).get(['answers'])?.getRawValue();
          const answerExplanationsArray = this.items?.at(indexQuestion).get(['answers'])?.getRawValue();
          for (let i = 0; i < question.answers.length; i++) {
            answersArray[i] = question.answers[i].answerTranslations[indexLanguage].answer;
            answerExplanationsArray[i] = question.answers[i].answerTranslations[indexLanguage].answerExplanation;
          }
          this.items?.at(indexQuestion).get(['answers'])?.setValue(answersArray);
          if (this.moduleType === moduleType.QuizIncremental) {
            this.items?.at(indexQuestion).get(['answerExplanation'])?.setValue(answerExplanationsArray);
          }
        }
      });
    }
  }

  saveTranslation() {
    this.saveTranslationEmitter.emit();
  }

  showLanguageState(languageId: number): boolean {
    let returnValue: boolean = true;
    let indexLanguage: number = this.moduleTranslations.map((e) => e.languageId).indexOf(languageId);

    if (this.moduleTranslations[indexLanguage].name == '') {
      returnValue = false;
    }

    this.quizQuestions.forEach((question) => {
      indexLanguage = question.questionTranslations.map((question) => question.languageId).indexOf(languageId);
      if (question.questionTranslations[indexLanguage].question == '') {
        returnValue = false;
      }

      if (this.moduleType === moduleType.QuizMultipleChoice) {
        question.answers.forEach((answer) => {
          if (answer.answerTranslations[indexLanguage].answer === '') {
            returnValue = false;
          }
        });
      }
    });

    return returnValue;
  }

  showLanguageStateQuestion(languageId: number, indexQuestion: number): boolean {
    const indexLanguage = this.quizQuestions[indexQuestion].questionTranslations.map((langTrueFalse) => langTrueFalse.languageId).indexOf(languageId);
    let returnValue = true;

    if (this.quizQuestions[indexQuestion].questionTranslations[indexLanguage].question == '') {
      return false;
    }

    if (this.moduleType === moduleType.QuizMultipleChoice) {
      this.quizQuestions[indexQuestion].answers.forEach((answer) => {
        if (answer.answerTranslations[indexLanguage].answer === '') {
          returnValue = false;
        }
      });
    }

    return returnValue;
  }

  //Quiz module
  addQuestion() {
    const questionTranslations: QuestionTranslation[] = [];

    this.languagesSelected.forEach((language) => {
      const languageQuestion: QuestionTranslation = {
        // TODO: check if it should be 0
        // We sometimes need to set this to 0, to indicate to EF Core that the row
        // should be inserted and not updated
        // See : https://learn.microsoft.com/en-us/dotnet/api/microsoft.entityframeworkcore.dbcontext.attach?view=efcore-8.0
        translatedEntityId: 0,
        languageId: language.id,
        question: '',
        answerExplanation: ''
      };
      questionTranslations.push(languageQuestion);
    });

    const newQuestion: Question = {
      questionTranslations: questionTranslations,
      correctAnswer: correctAnswerEnum.None,
      answers: [],
    };

    if (this.moduleType == this.enumModuleType.QuizMultipleChoice || this.moduleType == this.enumModuleType.QuizIncremental) {
      newQuestion.answers = [];
      // push newAnswer twice because we have a minimun of two answers.
      newQuestion.answers?.push(this.createNewAnswer());
      newQuestion.answers?.push(this.createNewAnswer());
    }

    this.quizQuestions.push(newQuestion);
    this.addFormItemWithoutQuestion();
    this.moduleService.setQuizQuestions(this.quizQuestions);
  }

  createAnswerTranslationList(languageList: Language[]): AnswerTranslation[] {
    const answerTranslations: AnswerTranslation[] = [];
    languageList.forEach((language) => {
      answerTranslations.push(new AnswerTranslation(0, language.id, '', ''));
    });
    return answerTranslations;
  }

  createNewAnswer(): Answer {
    return {
      answerTranslations: this.createAnswerTranslationList(this.languagesSelected),
    };
  }

  createFormItemTrueFalseWithQuestion(question: Question): FormGroup {
    const questionInput = question.questionTranslations.find((q) => q.languageId === this.multiLanguageForm.get(['currentLanguage'])?.value);
    let correctAnswer;
    switch (question.correctAnswer) {
      case correctAnswerEnum.True:
        correctAnswer = 'true';
        break;
      case correctAnswerEnum.False:
        correctAnswer = 'false';
        break;
      case correctAnswerEnum.None:
        correctAnswer = correctAnswerEnum.None;
        break;
    }

    return this.fb.group({
      questionInput: [questionInput?.question],
      correctAnswer: [correctAnswer],
      answerExplanation : [questionInput?.answerExplanation]
    });
  }

  createFormItemTrueFalseWithoutQuestion(): FormGroup {
    return this.fb.group({
      questionInput: [''],
      correctAnswer: [''],
      answerExplanation : ['']
    });
  }

  createFormItemMultipleChoiceWithQuestion(question: Question): FormGroup {
    const answers = new FormArray<FormControl<string | null>>([]);
    const languageId = this.multiLanguageForm.get(['currentLanguage'])?.value;

      const questionInput = question.questionTranslations.find((q) => q.languageId === languageId);
      question.answers?.forEach((answer) => {
        const stringAnswer = answer.answerTranslations.find((a) => a.languageId === languageId)!.answer;
        answers.push(new FormControl(stringAnswer));
      });

      let correctAnswer: number = 0;
      switch (question.correctAnswer) {
        case 1:
          correctAnswer = 1;
          break;
        case 2:
          correctAnswer = 2;
          break;
        case 4:
          correctAnswer = 3;
          break;
        case 8:
          correctAnswer = 4;
          break;
      }

      return this.fb.group({
        questionInput: [questionInput?.question],
        correctAnswer: new FormControl(correctAnswer),
        answersWithImage: [question.answersWithImages],
        answers: answers,
        answerExplanation: questionInput?.answerExplanation
      });
  } 
    
  createFormItemMultipleChoiceWithoutQuestion(): FormGroup {
    const answers = new FormArray<FormControl<string | null>>([]);
    answers.push(new FormControl(''));
    answers.push(new FormControl(''));
    return this.fb.group({
      questionInput: [''],
      correctAnswer: new FormControl(''),
      answersWithImage: [''],
      answers: answers,
      answerExplanation: new FormControl('')
    });
  }


  createFormItemIncrementalQuizWithQuestion(question: Question): FormGroup {
    const answers = new FormArray<FormControl<string | null>>([]);
    const answerExplanations = new FormArray<FormControl<string | null>>([]);
    const languageId = this.multiLanguageForm.get(['currentLanguage'])?.value;

      const questionInput = question.questionTranslations.find((q) => q.languageId === languageId);
      question.answers?.forEach((answer) => {
        const stringAnswer = answer.answerTranslations.find((a) => a.languageId === languageId);
        answers.push(new FormControl(stringAnswer!.answer));
        answerExplanations.push(new FormControl(stringAnswer!.answerExplanation));
      });

      return this.fb.group({
        questionInput: [questionInput?.question],
        answers: answers,
        answerExplanation: answerExplanations
      });
    } 
    
  createFormItemIncrementalQuizWithoutQuestion(): FormGroup {
    const answers = new FormArray<FormControl<string | null>>([]);
    const answerExplanations = new FormArray<FormControl<string | null>>([]);
    answers.push(new FormControl(''));
    answers.push(new FormControl(''));
    answerExplanations.push(new FormControl(''));
    answerExplanations.push(new FormControl(''));
    return this.fb.group({
      questionInput: [''],
      answers: answers,
      answerExplanation: answerExplanations
    });
  }

  createFormItemAnswer(answer?: string): FormControl {
    return new FormControl(answer || '');
  }

  addFormItemWithQuestion(question: Question): void {
    this.items = this.trueFalseQuizForm.get('items') as FormArray;
    const moduleType = this.moduleService.getModuleType();
    if (moduleType === this.enumModuleType.QuizTrueFalse) {
      this.items.push(this.createFormItemTrueFalseWithQuestion(question));
    } else if (moduleType === this.enumModuleType.QuizMultipleChoice) {
      this.items.push(this.createFormItemMultipleChoiceWithQuestion(question));
    } else if (moduleType === this.enumModuleType.QuizIncremental) {
      this.items.push(this.createFormItemIncrementalQuizWithQuestion(question));
    }
  }

  addFormItemWithoutQuestion(): void {
    this.items = this.trueFalseQuizForm.get('items') as FormArray;
    const moduleType = this.moduleService.getModuleType();
    if (moduleType === this.enumModuleType.QuizTrueFalse) {
      this.items.push(this.createFormItemTrueFalseWithoutQuestion());
    } else if (moduleType === this.enumModuleType.QuizMultipleChoice) {
      this.items.push(this.createFormItemMultipleChoiceWithoutQuestion());
    } else if (moduleType === this.enumModuleType.QuizIncremental) {
      this.items.push(this.createFormItemIncrementalQuizWithoutQuestion());
    }
  }

  removeQuestion(index: number) {
    this.quizQuestions.splice(index, 1);
    this.moduleService.setQuizQuestions(this.quizQuestions);

    this.quizQuestions.forEach((question, indexQuestion) => {
      const indexLanguage = question.questionTranslations.map((e) => e.languageId).indexOf(this.multiLanguageForm.get(['currentLanguage'])?.value);
      this.items?.at(indexQuestion).get(['questionInput'])?.setValue(question.questionTranslations[indexLanguage].question);
      if (this.moduleType === moduleType.QuizTrueFalse) {
        switch (question.correctAnswer) {
          case correctAnswerEnum.True:
            this.items?.at(indexQuestion).get(['correctAnswer'])?.setValue('true');
            break;
          case correctAnswerEnum.False:
            this.items?.at(indexQuestion).get(['correctAnswer'])?.setValue('false');
            break;
          case correctAnswerEnum.None:
            this.items?.at(indexQuestion).get(['correctAnswer'])?.setValue(correctAnswerEnum.None);
            break;
        }
      }
    });

    if (this.items && this.moduleType === moduleType.QuizMultipleChoice) {
      for (let i = index; i < this.items.length - 1; i++) {
        const lengthFirstQuestion = this.items.at(i).get(['answers'])?.value.length;
        const lengthSecondQuestion = this.items.at(i + 1).get(['answers'])?.value.length;

        if (lengthFirstQuestion > lengthSecondQuestion) {
          for (let j = lengthFirstQuestion - 1; j >= lengthFirstQuestion - lengthSecondQuestion; j--) {
            const answers = this.items.at(i).get(['answers']) as FormArray;
            answers.removeAt(j);
          }
        } else if (lengthFirstQuestion < lengthSecondQuestion) {
          for (let j = 0; j < lengthSecondQuestion - lengthFirstQuestion; j++) {
            const answers = this.items.at(i).get(['answers']) as FormArray;
            answers.push(new FormControl(''));
          }
        }
        this.items
          .at(i)
          .get(['correctAnswer'])
          ?.setValue(this.items?.at(i + 1).get(['correctAnswer'])?.value);
        this.items
          .at(i)
          .get(['answers'])
          ?.setValue(this.items?.at(i + 1).get(['answers'])?.value);
      }
    }

    this.items?.removeAt(this.items.length - 1);
    this.showQuestion.splice(index, 1);
  }

  saveAnswer(indexQuestion: number, indexAnswer: number) {
    const answerFromForm = this.items?.at(indexQuestion).getRawValue().answers[indexAnswer];
    const answerExplanationFromForm = this.items?.at(indexQuestion).getRawValue().answerExplanation[indexAnswer];
    const languageId = this.multiLanguageForm.get(['currentLanguage'])?.value;
    const indexLanguage = this.quizQuestions[indexQuestion].answers[indexAnswer].answerTranslations.map((e) => e.languageId).indexOf(languageId);
    // TODO: estiii
    this.quizQuestions[indexQuestion].answers[indexAnswer].answerTranslations[indexLanguage] = new AnswerTranslation(0, languageId, answerFromForm, answerExplanationFromForm);
    this.moduleService.setQuizQuestions(this.quizQuestions);
  }

  saveChange(indexQuestion: number) {
    const formQuestion = this.items?.at(indexQuestion).getRawValue();
    if (this.moduleType === moduleType.QuizTrueFalse) {
      // We have to do this so the correctAnswer is properly registered as an int ...
      if (formQuestion.correctAnswer === 'true') {
        this.quizQuestions[indexQuestion].correctAnswer = correctAnswerEnum.True;
      } else if (formQuestion.correctAnswer === 'false') {
        this.quizQuestions[indexQuestion].correctAnswer = correctAnswerEnum.False;
      } else {
        this.quizQuestions[indexQuestion].correctAnswer = correctAnswerEnum.None;
      }
    }
    const indexLanguage = this.quizQuestions[indexQuestion].questionTranslations.map((e) => e.languageId).indexOf(this.multiLanguageForm.get(['currentLanguage'])?.value);
    this.quizQuestions[indexQuestion].questionTranslations[indexLanguage].question = formQuestion.questionInput;
    if (this.moduleType !== moduleType.QuizIncremental){
      this.quizQuestions[indexQuestion].questionTranslations[indexLanguage].answerExplanation = formQuestion.answerExplanation;
    }
    this.moduleService.setQuizQuestions(this.quizQuestions);
  }

  // function saveChoiceAnswer when multiple answers will be allowed
  // saveChoiceAnswer(event: CheckboxChangeEvent, indexQuestion: number, indexAnswer: number) {
  //   const binaryNumber = this.quizQuestions[indexQuestion].correctAnswer;
  //   const binaryCalculation = Math.pow(2, indexAnswer);
  //   if (event.checked == true && this.quizQuestions[indexQuestion].correctAnswer != undefined) {
  //     this.quizQuestions[indexQuestion].correctAnswer = binaryNumber! + binaryCalculation;
  //   } else if (event.checked == false) {
  //     this.quizQuestions[indexQuestion].correctAnswer = binaryNumber! - binaryCalculation;
  //   }
  // }

  saveChoiceAnswer(indexQuestion: number, indexAnswer: number) {
    this.quizQuestions[indexQuestion].correctAnswer = Math.pow(2, indexAnswer);
  }

  showAndSetQuestion(showQuestion: boolean, indexQuestion: number) {
    this.showQuestion[indexQuestion] = showQuestion;
    if (this.moduleType === this.enumModuleType.QuizTrueFalse) {
      let answer;
      switch (this.quizQuestions[indexQuestion].correctAnswer) {
        case correctAnswerEnum.True:
          answer = 'true';
          break;
        case correctAnswerEnum.False:
          answer = 'false';
          break;
        case correctAnswerEnum.None:
          answer = undefined;
          break;
      }
      this.items?.at(indexQuestion).get(['correctAnswer'])?.setValue(answer);
    }
  }

  answers(indexQuestion: number): FormArray {
    return this.items?.at(indexQuestion).get(['answers']) as FormArray;
  }

  answerExplanation(): FormArray {
    return this.items?.at(0).get(['answerExplanation']) as FormArray;
  }

  addAnswer(indexQuestion: number) {
    if (this.quizQuestions[indexQuestion].answers?.length == 4 && this.moduleType === moduleType.QuizMultipleChoice) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Ajout de réponse impossible', detail: 'Une question ne peut pas contenir plus de 4 réponses.' });
      return;
    } else if (this.quizQuestions[0].answers?.length == 9 && this.moduleType === moduleType.QuizIncremental) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Ajout de réponse impossible', detail: 'Un quiz incrémental ne peut pas contenir plus de 9 réponses.' });
      return;
    }

    this.answers(indexQuestion).push(new FormControl(''));

    if (this.moduleType === moduleType.QuizIncremental) {
      this.answerExplanation().push(new FormControl(''));
    }

    const answerTranslations: AnswerTranslation[] = [];
    this.languagesSelected.forEach((language) => {
      if (this.moduleType == this.enumModuleType.QuizMultipleChoice || this.moduleType == this.enumModuleType.QuizIncremental) {
        answerTranslations.push(new AnswerTranslation(0, language.id, '', ''));
      }
    });

    const newAnswer: Answer = {
      answerTranslations: answerTranslations,
    };

    this.quizQuestions[indexQuestion].answers?.push(newAnswer);
  }

  removeAnswer(indexQuestion: number, indexAnswer: number) {
    const answerCount = this.quizQuestions[indexQuestion].answers.length;
    for (let i = indexAnswer; i < answerCount - 1; i++) {
      this.answers(indexQuestion)
        .at(i)
        .setValue(this.answers(indexQuestion).at(i + 1).value);

      if (this.moduleType === moduleType.QuizIncremental) {
        this.answerExplanation().at(i).setValue(this.answerExplanation().at(i + 1).value);
      }
    }
    let correctAnswerValue = this.items?.at(indexQuestion).get(['correctAnswer'])?.value;

    if (correctAnswerValue - 1 == indexAnswer) {
      this.quizQuestions[indexQuestion].correctAnswer = 0;
      this.items?.at(indexQuestion).get(['correctAnswer'])?.setValue(0);
    } else if (correctAnswerValue - 1 > indexAnswer) {
      correctAnswerValue--;
      this.items?.at(indexQuestion).get(['correctAnswer'])?.setValue(correctAnswerValue);
      this.quizQuestions[indexQuestion].correctAnswer = Math.pow(2, correctAnswerValue);
    }

    this.answers(indexQuestion).removeAt(answerCount - 1);
    if (this.moduleType === moduleType.QuizIncremental) {
      this.answerExplanation().removeAt(answerCount - 1);
    }
    this.quizQuestions[indexQuestion].answers?.splice(indexAnswer, 1);
  }

  // Images
  answerWithImage(event: CheckboxChangeEvent, indexQuestion: number) {
    this.quizQuestions[indexQuestion].answersWithImages = event.checked;
  }

  addImageQuestion(indexQuestion: number) {
    this.visibleMediaPopup = true;
    this.indexQuestionImage = indexQuestion;
  }

  addImageAnswer(indexQuestion: number, indexAnswer: number) {
    this.visibleMediaPopup = true;
    this.indexQuestionImage = indexQuestion;
    this.indexAnswerImage = indexAnswer;
  }

  removeImageQuestion(indexQuestion: number) {
    this.quizQuestions[indexQuestion].media = undefined;
    this.quizQuestions[indexQuestion].mediaId = undefined;
  }

  removeImageAnswer(indexQuestion: number, indexAnswer: number) {
    this.quizQuestions[indexQuestion].answers[indexAnswer].media = undefined;
    this.quizQuestions[indexQuestion].answers[indexAnswer].mediaId = undefined;
  }

  removeImageSynthesis() {
    this.imageSynthesis = undefined;
    const module = this.moduleService.getModule();
    module.mediaSynthesis = undefined;
    module.mediaSynthesisId = undefined;
    this.moduleService.setModule(module);
  }

  visualizeImageAnswer(indexQuestion: number, indexAnswer: number) {
    this.mediaVisualisationFromPage = true;
    this.selectedMedia = new MediaAssociation(0, this.quizQuestions[indexQuestion].answers[indexAnswer].media!, false, 0);
    this.dataService.getFile(this.selectedMedia.media).subscribe({
      next: (file) => {
        this.fileBlob = file;
        this.fileUrl = URL.createObjectURL(file);
      },
      error: (error) => {
        console.log(error.message);
      },
    });
    this.visibleMediaPopup = true;
  }

  visualizeImageQuestion(indexQuestion: number) {
    this.mediaVisualisationFromPage = true;
    this.selectedMedia = new MediaAssociation(0, this.quizQuestions[indexQuestion].media!, false, 0);
    this.dataService.getFile(this.selectedMedia.media).subscribe({
      next: (file) => {
        this.fileBlob = file;
        this.fileUrl = URL.createObjectURL(file);
      },
      error: (error) => {
        console.log(error.message);
      },
    });
    this.visibleMediaPopup = true;
  }

  visualizeImageSynthesis() {
    this.mediaVisualisationFromPage = true;
    this.selectedMedia = new MediaAssociation(0, this.imageSynthesis!, false, 0);
    this.dataService.getFile(this.selectedMedia.media).subscribe({
      next: (file) => {
        this.fileBlob = file;
        this.fileUrl = URL.createObjectURL(file);
      },
      error: (error) => {
        console.log(error.message);
      },
    });
    this.visibleMediaPopup = true;
  }

  associateMedia(media: Media) {
    this.closeMediaPopup();
    if (this.indexQuestionImage == -1) {
      this.imageSynthesis = media;
      const module = this.moduleService.getModule();
      module.mediaSynthesis = media;
      this.moduleService.setModule(module);
    } else if (this.indexAnswerImage !== -1) {
      this.quizQuestions[this.indexQuestionImage].answers[this.indexAnswerImage].media = media;
    } else {
      this.quizQuestions[this.indexQuestionImage].media = media;
    }
    this.indexQuestionImage = -1;
    this.indexAnswerImage = -1;
  }

  displayImageCompletionState(indexQuestion: number): boolean {
    const question = this.quizQuestions[indexQuestion];
    if (question.answersWithImages) {
      
      for (let i = 0; i < question.answers.length; i++){
        if (question.answers[i].media === undefined || question.answers[i].media === null) {
          return false;
        }
      }
    }
    return true;
  }

  closeMediaPopup() {
    this.visibleMediaPopup = false;
    this.mediaVisualisationFromPage = false;
  }

  countExplanationCharacters(indexQuestion: number) {
    return this.items?.getRawValue()[indexQuestion].answerExplanation.length;
  }

  //Quiz synthesis
  saveSynthesis() {

  }

  addImageSynthesis() {
    this.visibleMediaPopup = true;
  }

  countSynthesisCharacters() {
    return this.textSynthesis.value.length;
  }

  countNameCharacters(): string {
    return this.multiLanguageForm.get(['moduleName'])?.value.length;
  }

  countQuestionCharacters(indexQuestion: number): string {
    return this.items?.at(indexQuestion).getRawValue().questionInput.length;
  }

  countAnswerCharacters(indexQuestion: number, indexAnswer: number): string {
    return this.items?.at(indexQuestion).getRawValue().answers[indexAnswer].length;
  }

}
