import { Project } from "./project";

export class User {
  id!: number;
  username!: string;
  password!: string;
  emailAddress?: string;
  role!: string;
  projectIdList?: number[];
  projects: Project[] = [];

  constructor(id: number, username: string, password: string, emailAddress: string, role: string, projectIdList?: number[]) {
    this.id = id,
    this.username = username,
    this.password = password,
    this.emailAddress = emailAddress,
    this.role = role,
    this.projectIdList = projectIdList
  }
}
