<p-toast position="top-center"></p-toast>
<div class="app">
  @if (displayNavbar) {
    <div class="navbar">
      <app-navbar></app-navbar>
    </div>
  }

  <router-outlet></router-outlet>

  <app-file-upload-overlay></app-file-upload-overlay>
</div>
