<div class="card">
  <form [formGroup]="configurationForm">
    <h2 title="Cette section sert à configurer l'étape.">Configuration de l'étape<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>

    <div class="horizontal-display">
      <div class="vertical-display">
        <!-- <div> -->
        <div class="form-field">
          <span class="">
            <label htmlFor="pointOfInterestLabel">Label de l'étape</label>
            <p>Choisissez un nom qui ne sera visible que par vous pour identifier l'étape</p>
            <input pInputText id="pointOfInterestLabel" formControlName="pointOfInterestLabel" [maxlength]="inputLength.maxPointOfInterestLabel" />
            <small class="char-count">
              ({{ countLabelCharacters() }}/{{ inputLength.maxPointOfInterestLabel }})
            </small>
          </span>
        </div>
        @if (isLocated()) {
          <div class="form-field margin-top">
            <div class="localisation margin-top">
              <label htmlFor="latitude">Localisation</label>
            </div>
            <div class="vertical-display">
              <p>Latitude</p>
              <input pInputText id="latitude" formControlName="latitude" type="number" />
              <p>Longitude</p>
              <input pInputText id="longitude" formControlName="longitude" type="number" />
            </div>
          </div>
        }
      </div>

      <div class="vertical-display">
        <p>Image de l'étape, à venir prochainement.</p>
      </div>
    </div>
  </form>
</div>
