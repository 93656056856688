import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Module } from 'app/Models/module';
import { AuthService } from 'app/Services/Auth-Service/auth.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.css'],
})
export class ModuleListComponent {
  draggedModule?: Module = undefined;

  @Input() moduleList: Module[] = [];
  @Input() availableModuleList: Module[] = [];

  @Output() addModuleToPointOfInterest: EventEmitter<Module> = new EventEmitter<Module>();
  @Output() removeModuleToPointOfInterest: EventEmitter<Module> = new EventEmitter<Module>();

  dragged: boolean = false;
  indexDraggedItem: number = -1;

  mouseCoordinatesY: number = 0;

  mouseCoordMin!: number;
  mouseCoordMax!: number;

  cardPositionYbeforeMoving: number = 0;

  pointerComesFromOutside: boolean = false;

  distanceBetweenCards: number = 100;
  cardHeight!: number;

  @ViewChildren('dragItem', { read: ElementRef }) dragItems!: QueryList<ElementRef>;

  constructor(private authService: AuthService) {}

  addModuleDragStarted(module: Module) {
    this.draggedModule = module;
  }

  addModuleDroped(event: DragEvent) {
    event.preventDefault();

    if (this.draggedModule) {
      this.draggedModule.order = this.moduleList.length + 1;
      this.addModuleToPointOfInterest.emit(this.draggedModule);
    }

    this.draggedModule = undefined;
  }

  removeModule(moduleId: number) {
    const moduleToAdd = this.moduleList.find((module) => module.id == moduleId);
    if (moduleToAdd) {
      this.removeModuleToPointOfInterest.emit(moduleToAdd);
    }
  }

  //fuctions below drag and drop to reorder modules
  start(numberArray: number[]) {
    this.dragged = true;

    this.indexDraggedItem = numberArray[2];

    this.mouseCoordinatesY = numberArray[0];

    const element = this.dragItems.get(this.indexDraggedItem);

    if (element && this.moduleList) {
      this.cardPositionYbeforeMoving = element.nativeElement.style.top;
      this.cardHeight = element.nativeElement.clientHeight;

      this.mouseCoordMin = this.mouseCoordinatesY - this.indexDraggedItem * this.distanceBetweenCards - numberArray[1];
      this.mouseCoordMax = this.mouseCoordinatesY + (this.moduleList?.length - this.indexDraggedItem - 1) * this.distanceBetweenCards - numberArray[1] + this.cardHeight;
    }
  }

  move(event: MouseEvent) {
    event.preventDefault();
    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element && this.moduleList) {
      const newPosY = this.mouseCoordinatesY - event.y;
      this.mouseCoordinatesY = event.y;

      if (this.pointerComesFromOutside == true && (this.mouseCoordinatesY < this.mouseCoordMin + this.cardHeight / 2 || this.mouseCoordinatesY > this.mouseCoordMax - this.cardHeight / 2)) {
        //Pointer already leaved the list and is not able to drag yet
        // console.log('Pointer already leaved the list and is not able to drag yet');
      } else if (this.mouseCoordinatesY < this.mouseCoordMin) {
        // console.log('Pointer has left the list from above');
        // Pointer has left the list from above
        this.pointerComesFromOutside = true;
        element.nativeElement.style.top = 0;
      } else if (this.mouseCoordinatesY > this.mouseCoordMax) {
        // console.log('Pointer has left the list from below');
        // Pointer has left the list from below
        this.pointerComesFromOutside = true;
        element.nativeElement.style.top = this.moduleList.length * this.distanceBetweenCards - this.distanceBetweenCards + 'px';
      } else if (element.nativeElement.offsetTop - newPosY >= 0 && element.nativeElement.offsetTop - newPosY <= (this.dragItems.length - 1) * this.distanceBetweenCards) {
        // console.log('normal drag');
        // Normal drag
        this.pointerComesFromOutside = false;
        element.nativeElement.style.top = element.nativeElement.offsetTop - newPosY + 'px';
      }
    }
  }

  stop(event: MouseEvent) {
    event.preventDefault();

    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element) {
      const droppedCoordinatesY = element.nativeElement.offsetTop;

      const dropIndex = Math.round(droppedCoordinatesY / this.distanceBetweenCards);

      if (this.indexDraggedItem != dropIndex && this.moduleList) {
        const differenceIndex = dropIndex - this.indexDraggedItem;
        const temporaryPointOfInterest: Module = this.moduleList[this.indexDraggedItem];

        if (Math.sign(differenceIndex) == 1) {
          for (let i = 0; i < differenceIndex; i++) {
            this.moduleList[this.indexDraggedItem + i] = this.moduleList[this.indexDraggedItem + i + 1];
          }
        } else {
          for (let i = 0; i > differenceIndex; i--) {
            this.moduleList[this.indexDraggedItem + i] = this.moduleList[this.indexDraggedItem + i - 1];
          }
        }

        this.moduleList[dropIndex] = temporaryPointOfInterest;

        const idPointsOfInterestList: number[] = [];

        for (let i = 0; i < this.moduleList.length; i++) {
          idPointsOfInterestList[i] = this.moduleList[i].id;
        }
      } else {
        element.nativeElement.style.top = this.cardPositionYbeforeMoving;
      }

      this.affectOrderNumberToModules();

      this.dragged = false;
      this.indexDraggedItem = -1;
    }
  }

  affectOrderNumberToModules() {
    this.moduleList?.forEach((module, index) => {
      module.order = index + 1;
    });
  }

  // TODO: Improve the way to handle the situation
  onScrollEnd() {
    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element && this.moduleList) {
      element.nativeElement.style.top = this.indexDraggedItem * this.distanceBetweenCards + 'px';

      this.dragged = false;

      this.indexDraggedItem = -1;
    }
  }

  getHeightCard(): number {
    if (this.moduleList && this.moduleList.length > 2) {
      return this.moduleList.length * this.distanceBetweenCards + 200;
    } else {
      // return 180;
      return 400;
    }
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
