import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './Pages/Home/home-page/home-page.component';
import { AreaManagementPageComponent } from './Pages/Area-Management/area-management-page/area-management-page.component';
import { LoginPageComponent } from './Pages/Login/login-page/login-page.component';
import { ItineraryManagementPageComponent } from './Pages/Itinerary-Management/itinerary-management-page/itinerary-management-page.component';
import { PointOfInterestManagementPageComponent } from './Pages/PointOfInterest-Management/point-of-interest-management-page/point-of-interest-management-page.component';
import { DocumentLibraryPageComponent } from './Pages/Document-Library/document-library-page/document-library-page.component';
import { ModuleManagementPageComponent } from './Pages/Module-Management/module-management-page/module-management-page.component';
import { NotFoundPageComponent } from './Pages/Not-Found-404/not-found-page/not-found-page.component';
import { AdministationPageComponent } from './Pages/Administration/administation-page/administation-page.component';
import { authGuard } from './Guards/auth-guard.guard';
import { baseRouteRedirectGuard } from './Guards/base-route-redirect-guard.guard';
import { loginPageGuard } from './Guards/login-page-guard.guard';

const routes: Routes = [
  //TODO: check on children routes to add the project name in the Url
  // https://www.telerik.com/blogs/angular-basics-setting-up-child-routes-angular-12
  // https://blog.angular-university.io/angular2-router/
  // { path: '', redirectTo: ':projectName/home', pathMatch: 'full' },

  { path: '', component: HomePageComponent, pathMatch: 'full', canActivate: [baseRouteRedirectGuard] },
  { path: 'login', component: LoginPageComponent, canActivate: [loginPageGuard] },
  // {
  //   path: ':projectName',
  //   canActivate: [authGuard],
  //   children: [
  //     { path: 'home', component: HomePageComponent },
  //     { path: 'area-management', component: AreaManagementPageComponent },
  //     { path: 'itinerary-management/new', component: ItineraryManagementPageComponent },
  //     { path: 'itinerary-management/:id', component: ItineraryManagementPageComponent },
  //     { path: 'point-of-interest-management/new', component: PointOfInterestManagementPageComponent },
  //     { path: 'point-of-interest-management/:id', component: PointOfInterestManagementPageComponent },
  //     { path: 'document-library', component: DocumentLibraryPageComponent },
  //     { path: 'module-management', component: ModuleManagementPageComponent },
  //     { path: 'module-management/new-module', component: ModuleManagementPageComponent },
  //     { path: 'module-management/:id', component: ModuleManagementPageComponent },
  //     { path: 'administration', component: AdministationPageComponent }
  //   ],
  // },
  { path: ':projectName/home', component: HomePageComponent, canActivate: [authGuard] },
  { path: ':projectName/area-management', component: AreaManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/itinerary-management/new', component: ItineraryManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/itinerary-management/:id', component: ItineraryManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/point-of-interest-management/new', component: PointOfInterestManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/point-of-interest-management/:id', component: PointOfInterestManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/document-library', component: DocumentLibraryPageComponent, canActivate: [authGuard] },
  { path: ':projectName/module-management', component: ModuleManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/module-management/type-selection', component: ModuleManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/module-management/new-module', component: ModuleManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/module-management/:id', component: ModuleManagementPageComponent, canActivate: [authGuard] },
  { path: ':projectName/administration', component: AdministationPageComponent, canActivate: [authGuard] },
  { path: '**', component: NotFoundPageComponent, canActivate: [authGuard] },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
