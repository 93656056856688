<div class="body">
  <div class="page">
    <div class="entete">
      <div>
        <div class="poi-name-state">
          <h1 [title]="'Label de l\'étape : ' + pointOfInterest?.identificationName" class="truncate-one-line">{{ pointOfInterest?.identificationName || "Nouvelle étape" }}</h1>
          @if (pointOfInterest) {
            <span
              class="poi-state single-line"
              title="État de publication de l'étape"
              [ngClass]="pointOfInterest.state === enumPublishState.Published ? 'green-state' : pointOfInterest.state === enumPublishState.Modified ? 'orange-state' : 'red-state'">
              {{ displayPointOfInterestState() | titlecase }}
            </span>
          }
        </div>

        @if (pointOfInterest?.publicationDate) {
          <p>Dernière publication: {{ pointOfInterest?.publicationDate | date: "medium" }}</p>
        }
        @if (pointOfInterest?.lastModificationDate) {
          <p>Dernière modification: {{ pointOfInterest?.lastModificationDate | date: "medium" }}</p>
        }
      </div>

      @if (isCreation) {
        <button class="button" (click)="createPointOfInterest()" title="Créer l'étape">Créer</button>
      } @else {
        <p-splitButton label="Sauvegarder" title="Sauvegarder l'étape" (onClick)="updatePointOfInterest()" [model]="menuItems"></p-splitButton>
      }
    </div>

    <app-point-of-interest-configuration [configurationForm]="configurationForm"> </app-point-of-interest-configuration>

    <app-point-of-interest-multi-language-content
      [multiLanguageForm]="multiLanguageForm"
      [LanguageInfosPointOfInterest]="pointOfInterestTranslations"
      [languagesSelected]="languagesFromItinerary"
      (languageChangedEmitter)="languageChanged($event)"
      (saveLanguageEmitter)="saveLanguage()">
    </app-point-of-interest-multi-language-content>

    <app-module-list
      [moduleList]="moduleList"
      [availableModuleList]="availableModuleList"
      (addModuleToPointOfInterest)="addModuleDroped($event)"
      (removeModuleToPointOfInterest)="removeModule($event)">
    </app-module-list>
  </div>
</div>

<app-confirmation-popup [visible]="visible" [confirmTypeAction]="confirmTypeAction" [entityTypeAction]="entityTypeAction" (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
