<div class="body">
  <div class="page">
    <h1>Accueil - {{ this.getProjectName() }}</h1>

    @if (dashboard) {
      <!-- <div class="horizontal-display">
            <div class="display-cards">
              <h3>{{dashboard.itineraries?.length}}</h3>
            </div>
            <div class="display-cards">
              <h3>{{ pointOfInterestsCount() }}</h3>
            </div>
            <div class="display-cards">
              <h3>{{ modulesCount() }}</h3>
            </div>


          </div> -->

      <div class="itinerary-container">
        @for (itinerary of dashboard.itineraries; track itinerary; let itineraryIndex = $index) {
          <div class="itinerary-section">
            <!-- @if(itineraryIndex > 0){
                    <div 
                        class="horizontal-line">
                    </div>
                } -->

            <div class="horizontal-display">
              <div class="identification-section">
                @if (showPointOfInterests[itineraryIndex] === true) {
                  <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier parcours" (click)="showPointOfInterests[itineraryIndex] = false"> </i>
                } @else {
                  <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier parcours" (click)="showPointOfInterests[itineraryIndex] = true"> </i>
                }
                <p class="truncate-one-line" aria-hidden="true" tabindex="0" [title]="'Label du parcours : ' + itinerary.identificationName">Parcours: {{ itinerary.identificationName }}</p>
              </div>

              <div class="single-line">
                <span
                  class="publication-state"
                  title="État de publication du parcours"
                  [ngClass]="itinerary.state === enumPublishState.Published ? 'green-state' : itinerary.state === enumPublishState.Modified ? 'orange-state' : 'red-state'">
                  {{ displayPointOfInterestState(itinerary.state) | titlecase }}
                </span>
                <app-completion-state [completionState]="itinerary.readyForPublication" [tooltipTrue]="'Le parcours est complet'" [tooltipFalse]="'Le parcours n\'est pas complet'">
                </app-completion-state>

                <a routerLink="/{{ this.getProjectName() }}/itinerary-management/{{ itinerary.id }}" title="Visualiser le parcours" class="action-buttons-item">
                  <i class="bi bi-eye"></i>
                </a>
              </div>
            </div>

            @if (showPointOfInterests[itineraryIndex] === true && itinerary.pointOfInterests !== undefined && itinerary.pointOfInterests.length === 0) {
              <p class="POI-label">Aucune étape n'est associée à ce parcours</p>
            } @else if (showPointOfInterests[itineraryIndex] === true && itinerary.pointOfInterests) {
              @for (pointOfInterest of itinerary.pointOfInterests; track pointOfInterest; let pointOfInterestIndex = $index) {
                <div class="horizontal-line"></div>

                <div class="horizontal-display">
                  <div class="identification-section POI-label">
                    @if (pointOfInterest.showModules[pointOfInterestIndex] === true) {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier l'étape" (click)="pointOfInterest.showModules[pointOfInterestIndex] = false"> </i>
                    } @else {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier l'étape" (click)="pointOfInterest.showModules[pointOfInterestIndex] = true"> </i>
                    }

                    <p aria-hidden="true" tabindex="0" class="truncate-one-line" [title]="'Label de l\'étape : ' + pointOfInterest.identificationName">Étape: {{ pointOfInterest.identificationName }}</p>
                  </div>
                  <div class="single-line">
                    <app-completion-state [completionState]="pointOfInterest.readyForPublication" [tooltipTrue]="'L\'étape est complète'" [tooltipFalse]="'L\'étape n\'est pas complète'">
                    </app-completion-state>

                    <a routerLink="/{{ this.getProjectName() }}/point-of-interest-management/{{ pointOfInterest.id }}" title="Visualiser l'étape" class="action-buttons-item">
                      <i class="bi bi-eye"></i>
                    </a>
                  </div>
                </div>

                @if (pointOfInterest.showModules[pointOfInterestIndex] === true && pointOfInterest.modules?.length === 0) {
                  <p class="module-label">Aucune activité n'est associée à l'étape.</p>
                } @else if (pointOfInterest.showModules[pointOfInterestIndex] === true) {
                  @for (module of pointOfInterest.modules; track $index) {
                    <div class="horizontal-display">
                      <p class="module-label truncate-one-line" aria-hidden="true" tabindex="0" [title]="'Label de l\'activité : ' + module.identificationName">Activité: {{ module.identificationName }}</p>
                      <div class="single-line">
                        <app-completion-state [completionState]="module.readyForPublication" [tooltipTrue]="'L\'activité est complète'" [tooltipFalse]="'L\'activité n\'est pas complète'">
                        </app-completion-state>

                        <a routerLink="/{{ this.getProjectName() }}/module-management/{{ module.id }}" title="Visualiser l'activité" class="action-buttons-item">
                          <i class="bi bi-eye"></i>
                        </a>
                      </div>
                    </div>
                  }
                }
              }
            }
          </div>
        }
      </div>
    }
  </div>
</div>
